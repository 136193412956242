import { TFunction } from 'i18next'

import { filterEmptyValues } from 'src/lib/array-utils'
import {
	getCreditCardTypeNames,
	isCreditCardExpired,
	isCreditCardInvalid,
	isRestrictedForFees,
} from 'src/lib/credit-card-utils'
import { doesRoomAllowVpa } from 'src/lib/doesRoomAllowVpa'
import {
	CreditCardPaymentMethod,
	parseCreditCard,
	parseVirtualPaymentAutomation,
	PaymentMethod,
	VirtualPaymentAutomationPaymentMethod,
} from 'src/lib/payment-methods'
import { replaceBillingRelatedText } from 'src/lib/replaceBillingRelatedText'
import { getUnsupportedCardTypes, isSomeServiceRequiresCvv } from 'src/lib/trip-utils'
import { Maybe } from 'src/lib/types'
import {
	ItineraryServicesWithTravelers,
	ItineraryTripFee,
	WithItineraryServicesManagedByUser,
	WithServicesPermissions,
} from 'src/travelsuit'
import { UsageAllowedFor } from 'src/types/creditCard'
import {
	VirtualPaymentAutomationStatus,
	VirtualPaymentAutomationUsageAllowedFor,
} from 'src/types/virtualPaymentAutomation'

import { AdditionalFee, WithAdditionalFees } from './types'

function getForbiddenServiceName<T>(isServicePermitted: boolean, services: T[], serviceType: string) {
	return !isServicePermitted && !!services.length ? serviceType : undefined
}

function validateBookingsForCard(
	data: WithServicesPermissions,
	services: WithItineraryServicesManagedByUser & WithAdditionalFees,
	t: TFunction,
) {
	const feesToValidate: (ItineraryTripFee | AdditionalFee)[] | undefined = [
		...(services.trip_fees ?? []),
		...(services.additionalFees ?? []),
	]

	const unavailableServiceTypes = filterEmptyValues([
		getForbiddenServiceName(
			data.permitted_for_flights,
			services.flights_bookings,
			t('credit-card-validation.service.flight', 'flight'),
		),
		getForbiddenServiceName(
			data.permitted_for_hotels,
			services.hotels_bookings,
			t('credit-card-validation.service.hotel', 'hotel'),
		),
		getForbiddenServiceName(
			data.permitted_for_cars,
			services.cars_bookings,
			t('credit-card-validation.service.car', 'car'),
		),
		getForbiddenServiceName(
			data.permitted_for_fees,
			feesToValidate,
			replaceBillingRelatedText(
				t('credit-card-validation.service.fee', 'fee'),
				t('credit-card-validation.service.fulfilment-fee', 'Trip fulfilment fee'),
			),
		),
	])

	if (!unavailableServiceTypes.length) {
		return undefined
	}

	const lastService = unavailableServiceTypes.pop()
	const servicesLabel = unavailableServiceTypes.length
		? `${unavailableServiceTypes.join(', ')}${t('logical-group-text.and', ' and ')}${lastService}`
		: lastService

	return t(
		'credit-card-validation.not-allowed-for-services',
		'Your company does not allow this card to be used for {{services}} payments.',
		{ services: servicesLabel },
	)
}

function validateInvoiceProfilesForCard(
	card: { invoice_profile?: { id: number } | null },
	invoiceProfilesIds: Set<number | undefined>,
) {
	const cardInvoiceProfileId = card.invoice_profile?.id
	if (!cardInvoiceProfileId) {
		return false
	}

	if (invoiceProfilesIds.size !== 1) {
		return true
	}

	return !invoiceProfilesIds.has(cardInvoiceProfileId)
}

export type PaymentMethodValidator<T = PaymentMethod> = [(paymentMethod: T) => Maybe<boolean>, string | undefined]

export function calculateCardDisabledReason(paymentMethod: PaymentMethod, validators: Maybe<PaymentMethodValidator>[]) {
	return filterEmptyValues(
		filterEmptyValues(validators).map(([validate, reason]) => (validate(paymentMethod) ? reason : undefined)),
	)
}

function getFeesCreditCardDisabledReasons(paymentMethod: CreditCardPaymentMethod, t: TFunction) {
	const data = paymentMethod.getData()
	const messages: string[] = []
	if (isCreditCardExpired(data)) {
		messages.push(t('credit-card-validation.expired', 'This card has expired.'))
	}

	if (isCreditCardInvalid(data)) {
		messages.push(t('credit-card-validation.invalid', 'This card is invalid.'))
	}

	return messages
}

function getCreditCardPoolDisabledReasons(paymentMethod: VirtualPaymentAutomationPaymentMethod, t: TFunction) {
	return paymentMethod.getData().status !== VirtualPaymentAutomationStatus.Active
		? [t('credit-card-validation.invalid', 'This card is invalid.')]
		: []
}

export function getFeesCardDisabledReasons(paymentMethod: PaymentMethod, t: TFunction) {
	const additionalMessages =
		paymentMethod instanceof CreditCardPaymentMethod ? getFeesCreditCardDisabledReasons(paymentMethod, t) : []

	const feesMessages =
		(paymentMethod instanceof CreditCardPaymentMethod ||
			paymentMethod instanceof VirtualPaymentAutomationPaymentMethod) &&
		isRestrictedForFees(paymentMethod.getData())
			? [
					t(
						'credit-card-validation.not-allowed-for-services',
						'Your company does not allow this card to be used for {{services}} payments.',
						{
							services: replaceBillingRelatedText(
								t('credit-card-validation.service.fee', 'fee'),
								t('credit-card-validation.service.fulfilment-fee', 'Trip fulfilment fee'),
							),
						},
					),
				]
			: []

	return [
		...additionalMessages,
		...(paymentMethod instanceof VirtualPaymentAutomationPaymentMethod
			? getCreditCardPoolDisabledReasons(paymentMethod, t)
			: []),
		...feesMessages,
	]
}

export function mapPaymentMethods(
	paymentMethods: PaymentMethod[],
	services: ItineraryServicesWithTravelers & WithAdditionalFees,
	t: TFunction,
): PaymentMethod[] {
	const unsupportedCardTypes = getUnsupportedCardTypes(services)
	const travelersInvoiceProfilesIds = new Set(services.travelers.map((traveler) => traveler.user.invoice_profile?.id))
	const isCvvRequiredForServices = isSomeServiceRequiresCvv(services)

	return filterEmptyValues(
		paymentMethods.map((paymentMethod) => {
			if (paymentMethod instanceof CreditCardPaymentMethod) {
				const data = paymentMethod.getData()
				const clone = parseCreditCard(data)

				const cardType = data.card_type

				const validationMessages = filterEmptyValues([
					isCreditCardExpired(data) &&
						t(
							'trip-payment-method.disabled-tooltip.due-to-expiration-date',
							'This card is expired. Please use another card.',
						),
					isCreditCardInvalid(data) && t('credit-card-validation.invalid', 'This card is invalid.'),
					isCvvRequiredForServices &&
						!data.cvc_exist &&
						t(
							'trip-payment-method.disabled-tooltip.due-to-cvv-is-required',
							"This card can't be used because СVC/CVV is required.",
						),
					data.usage_allowed_for === UsageAllowedFor.InvoiceProfile &&
						validateInvoiceProfilesForCard(data, travelersInvoiceProfilesIds) &&
						t(
							'trip-payment-method.disabled-tooltip.due-to-invoice-profile',
							'Your company allows this card to be used only for travelers associated with this invoice profile.',
						),

					cardType &&
						unsupportedCardTypes.includes(cardType) &&
						t(
							'trip-payment-method.disabled-tooltip.due-to-hotel-is-not-supported-payment-system',
							'This hotel does not allow {{cardType}} cards to be used for this rate.',
							{ cardType: getCreditCardTypeNames(t)[cardType] },
						),
					validateBookingsForCard(data, services, t),
				])

				clone.setValidationTooltips(validationMessages)

				return clone
			}

			if (paymentMethod instanceof VirtualPaymentAutomationPaymentMethod) {
				const data = paymentMethod.getData()
				const clone = parseVirtualPaymentAutomation(data)

				const cardType = data.card_type

				const validationMessages = filterEmptyValues([
					...getCreditCardPoolDisabledReasons(paymentMethod, t),
					data.usage_allowed_for === VirtualPaymentAutomationUsageAllowedFor.InvoiceProfile &&
						validateInvoiceProfilesForCard(data, travelersInvoiceProfilesIds) &&
						t(
							'trip-payment-method.disabled-tooltip.due-to-invoice-profile',
							'Your company allows this card to be used only for travelers associated with this invoice profile.',
						),

					cardType &&
						unsupportedCardTypes.includes(cardType) &&
						t(
							'trip-payment-method.disabled-tooltip.due-to-hotel-is-not-supported-payment-system',
							'This hotel does not allow {{cardType}} cards to be used for this rate.',
							{ cardType: getCreditCardTypeNames(t)[cardType] },
						),
					validateBookingsForCard(data, services, t),
					!services.hotels_bookings.every(doesRoomAllowVpa) &&
						t(
							'trip-payment-method.disabled-tooltip.hotel-does-not-allow-vpa',
							'This hotel does not allow Virtual Payment Automation to be used for this rate.',
						),
				])

				clone.setValidationTooltips(validationMessages)

				return clone
			}

			return null
		}),
	)
}
