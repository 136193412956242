import React from 'react'

import RailFilledIcon from 'src/assets/train-card-filled.svg'
import SvgIcon from 'src/atoms/SvgIcon/SvgIcon'
import { addE2EAttrs, E2E } from 'src/lib/e2e-utils'
import { isFailedBooking } from 'src/lib/isFailedBooking'
import { unique } from 'src/lib/utils'
import AirplaneFilledIcon from 'src/refactor/assets/icons/airplane-filled.svg'
import CarFilledIcon from 'src/refactor/assets/icons/car-filled.svg'
import HotelFilledIcon from 'src/refactor/assets/icons/hotel-filled.svg'
import { disabledGray, mainBlack } from 'src/refactor/colors'
import styled from 'src/styles'
import { CancellationStatus, DefaultProps, Product, Trip, TripProductType, TripStatus } from 'src/travelsuit'

const TripStopsContainer = styled.div.attrs(addE2EAttrs)<E2E>``
const Badge = styled.div.attrs(addE2EAttrs)<{ color: string } & E2E>`
	display: inline-block;
	align-items: center;
	border: 1px solid ${disabledGray};
	border-radius: 5px;
	padding: 3px 6px;
	color: ${mainBlack};
	margin-bottom: 1ex;
	font-size: 12px;
	line-height: 18px;

	&:not(:last-child) {
		margin-right: 1ex;
	}
`

const StopIcon = styled(SvgIcon).attrs(addE2EAttrs)<E2E>`
	margin-right: 0.5ex;
	vertical-align: middle;
	color: ${(props) => props.color || mainBlack};
`

interface IProps extends DefaultProps, E2E {
	trip: Trip
	color: string
}

export const StopIconMap: Record<TripProductType, string> = {
	[TripProductType.Flight]: AirplaneFilledIcon,
	[TripProductType.Hotel]: HotelFilledIcon,
	[TripProductType.Car]: CarFilledIcon,
	[TripProductType.Rails]: RailFilledIcon,
}

function productKey(product: Product) {
	return [product?.start_time, product?.end_time, product?.from_location?.id, product?.to_location?.id].join('-')
}

function stopTitle(product: Product): React.ReactNode {
	return unique([product.from_location?.short_name, product.to_location?.short_name]).filter(Boolean).join(' - ')
}

const STATUSES_FOR_FILTERING_PRODUCTS = [TripStatus.Approved, TripStatus.Booked, TripStatus.Canceled]
function filterProductsWithFailedStatus(products: Product[]): Product[] {
	return products.filter((product) => !isFailedBooking(product))
}

const TripStops: React.FunctionComponent<IProps> = (props) => {
	const { className, trip, color, e2e } = props
	const uniqueProducts = unique(
		trip.products.filter((prod) => prod.cancellation_status !== CancellationStatus.Cancelled),
		productKey,
	)

	const products = STATUSES_FOR_FILTERING_PRODUCTS.includes(trip.status)
		? filterProductsWithFailedStatus(uniqueProducts)
		: uniqueProducts

	return (
		<TripStopsContainer className={className} e2e={e2e}>
			{products.map((product, i) => (
				<Badge e2e="TripStop" color={color} key={[product.start_time, product.end_time, i].join('-')}>
					<StopIcon
						e2e={`TripStopIcon.${product.product_type}`}
						src={StopIconMap[product.product_type]}
						color={color.toString()}
					/>
					{stopTitle(product)}
				</Badge>
			))}
		</TripStopsContainer>
	)
}

export default TripStops
