import { areMultipleServiceTypesPresent } from 'src/lib/areMultipleServiceTypesPresent'
import { filterEmptyValues } from 'src/lib/array-utils'
import { getNumberOfServiceTypes } from 'src/lib/getNumberOfServiceTypes'
import { parseSyntheticIdToApi, SyntheticPaymentMethodId } from 'src/lib/payment-methods'
import {
	ServicePaymentMethodsWithFeeCard,
	TravelerServicePaymentMethods,
	UserPaymentMethods,
	WithItineraryServicesManagedByUser,
	WithMultipleCreditCards,
	WithPaymentMethodId,
	WithPaymentMethods,
} from 'src/travelsuit'

import { calculateFormStateDumpByServices } from './calculateFormStateDumpByServices'
import { PaymentMethodControl } from './PaymentMethodControl'
import {
	AdditionalFee,
	AdditionalFeesCalculatorInterface,
	ChangeListener,
	ConfigPaymentMethodId,
	CvcData,
	CvcStorageInterface,
	FormStateDump,
	FormUserId,
	ServiceId,
	ServiceKey,
	ServiceKeyEntry,
	ServicesControl,
	ServicesPaymentConfig,
	ServicesPaymentMethodIds,
	USER_ID_FOR_COMMON_PAYMENT,
	WithAdditionalFees,
	WithCvcData,
	WithFormData,
	WithFormMetadata,
	WithFormUserId,
	WithValidity,
} from './types'
import { fillMissingServices } from './utils'

type PaymentControlsByService = Map<ServiceKey, PaymentMethodControl>

type FormState = Map<FormUserId, PaymentControlsByService>

function getNextValueFromIterator<K>(iterator: MapIterator<K>) {
	const nextResult = iterator.next()
	if (nextResult.done) {
		throw new Error('No more values')
	}

	return nextResult.value as K
}

export class PaymentMethodsForm
	implements
		WithFormData<WithMultipleCreditCards<TravelerServicePaymentMethods> | WithPaymentMethodId>,
		WithCvcData,
		WithValidity
{
	private formState: FormState

	private isLoading = false

	constructor(
		private formMetadata: WithFormMetadata,
		private additionalFeesCalculator: AdditionalFeesCalculatorInterface,
		private cvcStorage: CvcStorageInterface,
		private loadCommonPaymentMethods: () => Promise<WithPaymentMethods>,
		private loadSeparatePaymentMethods: () => Promise<UserPaymentMethods[]>,
		private changeListener: ChangeListener | undefined,
	) {
		this.formState = new Map()
	}

	getIsLoading() {
		return this.isLoading
	}

	private setIsLoading(isLoading: boolean) {
		this.isLoading = isLoading
		this.notifyChange()
	}

	removeChangeListener() {
		this.changeListener = undefined
	}

	private getServicesForUser(userId: FormUserId) {
		const services = this.formMetadata.getServicesByUser().get(userId)

		if (!services) {
			throw new Error(`No services found for user ${userId}`)
		}

		return services
	}

	private overwritePaymentControlsForUser({
		userId,
		writer,
	}: {
		userId: FormUserId
		writer: (paymentConfigByService: PaymentControlsByService) => void
	}) {
		let paymentConfig = this.getPaymentsControlsForUser(userId)
		paymentConfig?.clear()

		if (!paymentConfig) {
			paymentConfig = new Map()
			this.formState.set(userId, paymentConfig)
		}

		writer(paymentConfig)
	}

	private addCombinedControlForUser({
		userId,
		paymentMethodId,
	}: {
		userId: FormUserId
		paymentMethodId?: SyntheticPaymentMethodId
	}) {
		this.overwritePaymentControlsForUser({
			userId,
			writer: (paymentConfig) => {
				paymentConfig.set(
					'combined',
					this.createPaymentMethodControl({
						userId,
						config: { services: this.getServicesForUser(userId), selectedPaymentMethodId: paymentMethodId },
						serviceKey: 'combined',
					}),
				)
			},
		})
	}

	private addSeparateServicesControlsForUser({
		userId,
		servicesPaymentMethodsIds,
		shouldAddServices = (services) => !!services?.length,
	}: {
		userId: FormUserId
		servicesPaymentMethodsIds?: Partial<ServicesPaymentMethodIds>
		shouldAddServices?: (services?: unknown[]) => boolean
	}) {
		const additionalFees = this.formMetadata.getAdditionalFeesByUser().get(userId)

		this.overwritePaymentControlsForUser({
			userId,
			writer: (paymentConfig) => {
				const services = this.getServicesForUser(userId)

				const servicesEntries: [ServiceKey, Partial<WithItineraryServicesManagedByUser & WithAdditionalFees>][] =
					filterEmptyValues([
						shouldAddServices(services.flights_bookings)
							? ['flights_bookings', { flights_bookings: services.flights_bookings }]
							: undefined,
						shouldAddServices(services.hotels_bookings)
							? ['hotels_bookings', { hotels_bookings: services.hotels_bookings }]
							: undefined,
						shouldAddServices(services.cars_bookings)
							? ['cars_bookings', { cars_bookings: services.cars_bookings }]
							: undefined,
						shouldAddServices(services.trip_fees?.length ? services.trip_fees : additionalFees)
							? ['trip_fees', { trip_fees: services.trip_fees, additionalFees }]
							: undefined,
						shouldAddServices(services.rails_bookings)
							? ['rails_bookings', { rails_bookings: services.rails_bookings }]
							: undefined,
					])

				servicesEntries.forEach(([key, servicesPart]) => {
					paymentConfig.set(
						key,
						this.createPaymentMethodControl({
							userId,
							config: {
								services: fillMissingServices({ ...servicesPart, travelers: services.travelers }),
								selectedPaymentMethodId: servicesPaymentMethodsIds?.[key],
							},
							serviceKey: key,
						}),
					)
				})
			},
		})
	}

	private calculateUserAdditionalFees({ userId }: WithFormUserId) {
		const controls = this.getDefinedPaymentControlsForUser(userId)

		const additionalFees: AdditionalFee[] = []
		for (const serviceControl of controls.values()) {
			const paymentMethod = serviceControl.getSelectedPaymentMethod()

			if (!paymentMethod) {
				continue
			}

			additionalFees.push(
				...this.additionalFeesCalculator.calculateAdditionalFees(paymentMethod, serviceControl.getServices()),
			)
		}

		this.formMetadata.getAdditionalFeesByUser().set(userId, additionalFees)
	}

	private updateUserControlsByAdditionalFees({ userId }: WithFormUserId) {
		const additionalFees = this.formMetadata.getAdditionalFeesByUser().get(userId)
		const hasAdditionalFees = !!additionalFees?.length

		const controls = this.getDefinedPaymentControlsForUser(userId)

		const combinedConfig = controls.get('combined')
		const feesConfig = controls.get('trip_fees')

		const isFeesConfigEmpty = feesConfig && !feesConfig.getServices().trip_fees?.length

		if (!hasAdditionalFees && isFeesConfigEmpty) {
			controls.delete('trip_fees')

			if (controls.size === 1) {
				const control = getNextValueFromIterator(controls.values())
				this.addCombinedControlForUser({
					userId,
					paymentMethodId: control.getSelectedPaymentMethod()?.getId(),
				})
			}
			return
		}

		if (!hasAdditionalFees || !!feesConfig) {
			return
		}

		if (combinedConfig && !combinedConfig.getServices().trip_fees?.length) {
			const selectedPaymentMethod = combinedConfig.getSelectedPaymentMethod()
			const paymentMethodId = selectedPaymentMethod?.getId()
			this.addSeparateServicesControlsForUser({
				userId,
				servicesPaymentMethodsIds: {
					flights_bookings: paymentMethodId,
					hotels_bookings: paymentMethodId,
					cars_bookings: paymentMethodId,
				},
			})
		}
	}

	private recalculateAdditionalFees() {
		for (const userId of this.formState.keys()) {
			this.calculateUserAdditionalFees({ userId })
		}
	}

	private updateConfigsWithAdditionalFees() {
		for (const userId of this.formState.keys()) {
			this.updateUserControlsByAdditionalFees({ userId })
		}
	}

	private resetFormState(writer: () => void) {
		this.formState.clear()
		writer()
		this.processPaymentMethodsChange()
	}

	private setCommonPaymentMethodFormState({ paymentMethodId }: { paymentMethodId?: SyntheticPaymentMethodId } = {}) {
		this.resetFormState(() => {
			this.addCombinedControlForUser({ userId: USER_ID_FOR_COMMON_PAYMENT, paymentMethodId })
		})
	}

	private setSeparatePaymentMethodsForEachUserFormState() {
		this.resetFormState(() => {
			for (const userId of this.formMetadata.getPaymentMethodsByUser().keys()) {
				this.addCombinedControlForUser({ userId })
			}
		})
	}

	private getPaymentsControlsForUser(userId: FormUserId) {
		return this.formState.get(userId)
	}

	private getDefinedPaymentControlsForUser(userId: FormUserId) {
		const configs = this.getPaymentsControlsForUser(userId)

		if (!configs) {
			throw new Error(`No config for user ${userId}`)
		}

		return configs
	}

	private getCommonServices() {
		return this.getServicesForUser(USER_ID_FOR_COMMON_PAYMENT)
	}

	areSeparatePaymentMethodsForEachUserAvailable() {
		return this.getCommonServices().travelers.length > 1
	}

	areSeparatePaymentMethodsForEachServiceAvailableForUser(userId: FormUserId) {
		return areMultipleServiceTypesPresent(this.getServicesForUser(userId))
	}

	isUsingSinglePaymentMethodForEachService(userId: FormUserId) {
		return this.getPaymentsControlsForUser(userId)?.size === 1
	}

	isUsingCommonPaymentMethodForEachTraveler() {
		return this.formState.size === 1
	}

	isUsingCommonPaymentMethodForEachService() {
		return (
			this.isUsingCommonPaymentMethodForEachTraveler() && getNextValueFromIterator(this.formState.values()).size === 1
		)
	}

	toggleSeparatePaymentMethodsForUser(userId: FormUserId) {
		if (this.isUsingSinglePaymentMethodForEachService(userId)) {
			this.addSeparateServicesControlsForUser({ userId })
		} else {
			this.addCombinedControlForUser({ userId })
		}

		this.processPaymentMethodsChange()
	}

	getUsers() {
		return this.getCommonServices().travelers.map(({ user }) => user)
	}

	private async getAndCalculateSeparatePaymentMethodForEachTraveler() {
		const usersPaymentMethods = await this.loadSeparatePaymentMethods()
		this.formMetadata.setPaymentMethodsByUser(usersPaymentMethods)
	}

	private async switchToSeparatePaymentMethodForEachTraveler() {
		this.processFormReload(async () => {
			await this.getAndCalculateSeparatePaymentMethodForEachTraveler()
			this.setSeparatePaymentMethodsForEachUserFormState()
		})
	}

	private async getAndCalculateCommonPaymentMethods() {
		const commonPaymentMethods = await this.loadCommonPaymentMethods()
		this.formMetadata.setPaymentMethodsByUser([{ user_id: USER_ID_FOR_COMMON_PAYMENT, ...commonPaymentMethods }])
	}

	private async switchToCommonPaymentMethodForEachTraveler() {
		this.processFormReload(async () => {
			await this.getAndCalculateCommonPaymentMethods()
			this.setCommonPaymentMethodFormState()
		})
	}

	private async processFormReload(writer: () => Promise<void>) {
		this.setIsLoading(true)
		await writer()
		this.setIsLoading(false)
	}

	toggleCommonPaymentMethodForEachTraveler() {
		if (!this.isUsingCommonPaymentMethodForEachTraveler()) {
			this.switchToCommonPaymentMethodForEachTraveler()
		} else {
			this.switchToSeparatePaymentMethodForEachTraveler()
		}
	}

	private notifyChange() {
		this.changeListener?.()
	}

	private processPaymentMethodsChange() {
		this.recalculateAdditionalFees()
		this.updateConfigsWithAdditionalFees()
		this.notifyChange()
	}

	private createPaymentMethodControl({
		userId,
		config,
		serviceKey,
	}: ServiceId & {
		config: ServicesPaymentConfig
	}) {
		return new PaymentMethodControl(
			this.formMetadata,
			{ userId, serviceKey },
			() => {
				this.calculateUserAdditionalFees({ userId })
				this.updateUserControlsByAdditionalFees({ userId })
				this.notifyChange()
			},
			config,
			this.cvcStorage,
			userId,
		)
	}

	getPaymentMethodControlForUserService({ userId, serviceKey }: ServiceId): ServicesControl | undefined {
		return this.getPaymentsControlsForUser(userId)?.get(serviceKey)
	}

	getDefinedPaymentMethodControlForUserService(args: ServiceId): ServicesControl {
		const servicesControl = this.getPaymentMethodControlForUserService(args)

		if (!servicesControl) {
			throw new Error(`No config for user ${args.userId} and service ${args.serviceKey}`)
		}

		return servicesControl
	}

	getAllAdditionalFees() {
		const additionalFees: AdditionalFee[] = []

		for (const userAddtionalFees of this.formMetadata.getAdditionalFeesByUser().values()) {
			additionalFees.push(...(userAddtionalFees ?? []))
		}

		return additionalFees
	}

	private getUserServicesControlsIterator() {
		return this.formState.entries()
	}

	private *getServicesControlsGenerator() {
		for (const [, userControl] of this.getUserServicesControlsIterator()) {
			for (const serviceControl of userControl.values()) {
				yield serviceControl
			}
		}
	}

	isValid() {
		for (const serviceControl of this.getServicesControlsGenerator()) {
			if (!serviceControl.isValid()) {
				return false
			}
		}

		return true
	}

	private parsePaymentMethodIdToApiForService(controls: PaymentControlsByService, serviceKey: ServiceKey) {
		const paymentMethodId = controls.get(serviceKey)?.getSelectedPaymentMethod()?.getId()
		return paymentMethodId ? parseSyntheticIdToApi(paymentMethodId) : null
	}

	private getPaymentMethodsDataForUser(userControls: PaymentControlsByService): ServicePaymentMethodsWithFeeCard {
		const combinedControlPaymentMethodId = this.parsePaymentMethodIdToApiForService(userControls, 'combined')
		if (combinedControlPaymentMethodId) {
			return {
				flights: combinedControlPaymentMethodId,
				hotels: combinedControlPaymentMethodId,
				cars: combinedControlPaymentMethodId,
				rails: combinedControlPaymentMethodId,
				fees: combinedControlPaymentMethodId,
			}
		}

		return {
			flights: this.parsePaymentMethodIdToApiForService(userControls, 'flights_bookings'),
			hotels: this.parsePaymentMethodIdToApiForService(userControls, 'hotels_bookings'),
			cars: this.parsePaymentMethodIdToApiForService(userControls, 'cars_bookings'),
			rails: this.parsePaymentMethodIdToApiForService(userControls, 'rails_bookings'),
			fees: this.parsePaymentMethodIdToApiForService(userControls, 'trip_fees'),
		}
	}

	getFormData() {
		const iterator = this.getUserServicesControlsIterator()

		if (!this.isUsingCommonPaymentMethodForEachTraveler()) {
			return {
				multiple_credit_cards: [...iterator].map(([userId, controls]) => ({
					user_id: userId!,
					...this.getPaymentMethodsDataForUser(controls),
				})),
			}
		}

		const controls = getNextValueFromIterator(iterator)[1]

		if (controls.has('combined')) {
			const withApiPaymentMethodId = this.parsePaymentMethodIdToApiForService(controls, 'combined')

			if (!withApiPaymentMethodId) {
				throw new Error('No payment method id for combined payment')
			}
			return withApiPaymentMethodId
		}

		const paymentsMethodsData = this.getPaymentMethodsDataForUser(controls)

		return {
			multiple_credit_cards: this.getCommonServices().travelers.map(({ user }) => ({
				user_id: user.id,
				...paymentsMethodsData,
			})),
		}
	}

	getCvcData(): CvcData {
		const cvcData: CvcData = {}

		for (const control of this.getServicesControlsGenerator()) {
			const paymentMethod = control.getSelectedPaymentMethod()
			const cvcValue = control.getCvcControl()?.getData()?.value

			if (!cvcValue || !paymentMethod) {
				continue
			}

			cvcData[paymentMethod.getData().id] = cvcValue
		}

		return cvcData
	}

	getStateDump(): FormStateDump {
		const formStateDump: FormStateDump = []
		for (const [userId, controls] of this.getUserServicesControlsIterator()) {
			const dumpRecords: ServiceKeyEntry[] = []
			for (const [serviceKey, control] of controls.entries()) {
				dumpRecords.push([serviceKey, control.getSelectedPaymentMethod()?.getId()])
			}

			formStateDump.push([userId, dumpRecords])
		}

		return formStateDump
	}

	private addDumpConfigsForUser({
		userId,
		paymentMethodsIdsByService,
	}: {
		userId: FormUserId
		paymentMethodsIdsByService?: Map<ServiceKey, ConfigPaymentMethodId>
	}) {
		if (!paymentMethodsIdsByService || paymentMethodsIdsByService.has('combined')) {
			this.addCombinedControlForUser({ userId, paymentMethodId: paymentMethodsIdsByService?.get('combined') })
			return
		}

		this.addSeparateServicesControlsForUser({
			userId,
			servicesPaymentMethodsIds: {
				flights_bookings: paymentMethodsIdsByService.get('flights_bookings'),
				hotels_bookings: paymentMethodsIdsByService.get('hotels_bookings'),
				cars_bookings: paymentMethodsIdsByService.get('cars_bookings'),
				trip_fees: paymentMethodsIdsByService.get('trip_fees'),
			},
			shouldAddServices: () => true,
		})
	}

	private populateFormStateFromDump(formStateDump: FormStateDump | undefined) {
		const dumpServicesPaymentsInfo = new Map(
			(formStateDump ?? calculateFormStateDumpByServices(this.formMetadata.getServicesByUser())).map(
				([userId, entries]) => [userId, new Map<ServiceKey, ConfigPaymentMethodId>(entries)],
			),
		)

		if (!dumpServicesPaymentsInfo.size || dumpServicesPaymentsInfo.has(USER_ID_FOR_COMMON_PAYMENT)) {
			this.addDumpConfigsForUser({
				userId: USER_ID_FOR_COMMON_PAYMENT,
				paymentMethodsIdsByService: dumpServicesPaymentsInfo.get(USER_ID_FOR_COMMON_PAYMENT),
			})
			return
		}

		this.getCommonServices().travelers.forEach(({ user: { id } }) => {
			this.addDumpConfigsForUser({
				userId: id,
				paymentMethodsIdsByService: dumpServicesPaymentsInfo.get(id),
			})
		})
	}

	private removeControlsWithEmptyServices() {
		for (const [userId, configs] of this.formState.entries()) {
			const entries = [...configs.entries()]
			entries.forEach(([serviceKey, control]) => {
				const numberOfServiceTypes = getNumberOfServiceTypes(control.getServices())

				const additionalFeesQuantity =
					serviceKey === 'trip_fees' ? this.formMetadata.getAdditionalFees({ userId, serviceKey })?.length || 0 : 0

				if (!numberOfServiceTypes && !additionalFeesQuantity) {
					configs.delete(serviceKey)
				}
			})

			if (configs.size === 1 && !configs.has('combined')) {
				const combinedConfig = getNextValueFromIterator(configs.values())
				configs.clear()
				configs.set('combined', combinedConfig)
			}
		}
	}

	async restoreFromDump(formStateDump: FormStateDump | undefined) {
		this.processFormReload(async () => {
			this.populateFormStateFromDump(formStateDump)

			if (this.isUsingCommonPaymentMethodForEachTraveler()) {
				await this.getAndCalculateCommonPaymentMethods()
			} else {
				await this.getAndCalculateSeparatePaymentMethodForEachTraveler()
			}

			this.recalculateAdditionalFees()
			this.removeControlsWithEmptyServices()
			this.notifyChange()
		})
	}
}
