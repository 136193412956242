import { useDispatch } from 'react-redux'

import { Itinerary } from 'src/travelsuit'
import { FlightPolicyDeviation } from 'src/types/flights'

export enum POSITIONS {
	// W - means window seat
	W = 'W',
	// A - means aisle seat
	A = 'A',
	// C - means center seat
	C = 'C',
	// WA/AW - means window on one side and aisle on the other side
	WA = 'WA',
	AW = 'AW',
	N = 'NONE',
}

export enum SEAT_STATUSES {
	// T - means taken
	T = 'T',
	// A - means available
	A = 'A',
	// N - means no seat at this location,
	N = 'N',
}

export enum ROW_CHARACTERISTICS {
	// Z - means row doesn't exist
	Z = 'Z',
	// E - means exit row
	E = 'E',
	// K - means overwing row
	K = 'K',
}

export enum SEAT_CHARACTERISTICS {
	// OW - means overwing seat(s)
	OW = 'OW',
	// E - means seat doesn't exist
	E = '8',
	// L - means Leg space seat
	L = 'L',
	// 'CH' - chargable seat
	CH = 'CH',
	// 'A' - Aisle Seat
	A = 'A',
	// 'O' - Preferential position
	O = 'O',
	// '9' - 'Center seat (not window, not aisle)'
	CENTER_SEAT = '9',
	// 'W' - Window seat
	W = 'W',
}

//* Seat map typings

export interface SeatMapInfoItem<T = string> {
	code: T
	description: string
}

export interface SeatMapColumn {
	index: string
	position: SeatMapInfoItem<POSITIONS>
}

export interface SeatMapSeat {
	amount: number
	converted_amount: number
	characteristics: SeatMapInfoItem<SEAT_CHARACTERISTICS>[]
	column: string
	seatKey: string
	status: SeatMapInfoItem<SEAT_STATUSES>
	number: string
	in_policy: boolean
	policyDeviations: FlightPolicyDeviation[]
	airportLabel?: string
	segmentId?: string
	traveler_id?: number
	travelerDetails?: TravelerDetail[]
	seat_key?: string
	flight_segment_id?: string
	description?: string | null
	/** Becomes a boolean after the seat booking attempt */
	is_success?: boolean | null
}

export interface ManageItinerarySeatData {
	flight_segment_id: string
	description: null
	policy_deviations: FlightPolicyDeviation[]
	status: SeatMapInfoItem<SEAT_STATUSES>
	amount: number
	seat_key: string
	in_policy: boolean
	number: string
	traveler_id: number
	characteristics: SeatMapInfoItem<SEAT_CHARACTERISTICS>[]
	column: string
}

interface TravelerDetail {
	amount: number
	convertedAmount: number
	inPolicy: boolean
	policyDeviations: FlightPolicyDeviation[]
	travelerId: number
}

export interface SeatMapRow {
	characteristics: SeatMapInfoItem<ROW_CHARACTERISTICS>[] | null
	number: number | null
	seats: SeatMapSeat[] | null
}

export interface SeatMapDeck {
	cabin: SeatMapInfoItem
	columns: SeatMapColumn[]
	rows: SeatMapRow[]
	location: SeatMapInfoItem
	overwingRowNumbers: {
		first: number
		last: number
	}
	rowNumbers: {
		first: number
		last: number
	}
}

export interface SeatMapType {
	decks: SeatMapDeck[]
	errors: string[]
	segment: {
		segmentIdRef: number
	}
}

export interface ParsedSeatMap {
	decks: SeatMapDeck[] | null
	errors: string[] | null
	segmentIndex: number
}

export interface SeatMapParams {
	fare_group_key?: string
	flight_option_keys?: string[]
	users_id: (number | null)[]
	search_id?: number
	flight_identifier?: string
	booking_segment_id?: number
}

export const INITIAL_SEAT_MAP_SET: SeatMapSeat = {
	amount: 0,
	converted_amount: 0,
	characteristics: [
		{
			code: SEAT_CHARACTERISTICS.L,
			description: 'Leg space',
		},
	],
	column: '',
	seatKey: '',
	status: {
		code: SEAT_STATUSES.A,
		description: 'Available',
	},
	number: '',
	in_policy: false,
	policyDeviations: [],
	airportLabel: '',
	segmentId: '',
	traveler_id: 0,
	travelerDetails: [],
	seat_key: '',
	flight_segment_id: '',
}

export const INITIAL_PARSED_SEATMAP: ParsedSeatMap = {
	decks: null,
	errors: null,
	segmentIndex: 0,
}

export interface ManageSeatParams {
	dispatch: ReturnType<typeof useDispatch>
	tripId: number
	isMultipleFlight: boolean
	selectedFlightId: number
	location: Pick<Location, 'reload'>
}

export interface getFilteredSelectedSeatsParams {
	itinerary: Itinerary
	noSelectedSeats: boolean
	selectedFlightId: number
}
