import React from 'react'
import { Link } from 'react-router-dom'
import { CSSProperties } from 'styled-components'

import { bgMaterial, boxShadowMedium } from 'src/_vars'
import Tooltip from 'src/atoms/Tooltip/Tooltip'
import { forceArray } from 'src/lib/array-utils'
import { addE2EAttrs, E2E } from 'src/lib/e2e-utils'
import { exclude } from 'src/lib/utils'
import { font, FontType } from 'src/refactor/fonts'
import styled from 'src/styles'
import { DefaultProps } from 'src/travelsuit'

import { LinkLike } from '../GenericComponents/GenericComponents'
import Tab from '../Tab/Tab'

export type IProps<TabKey extends string = string> = DefaultProps & {
	active: TabKey
	children: JSX.Element | Array<JSX.Element | null | undefined>
	variant?: 'normal' | 'condensed'
	alignment?: CSSProperties['textAlign']
	disableRouting?: boolean
	boxShadow?: string
	onChange?(key: TabKey): void
	e2eForTabStrips?: string
	noWrap?: boolean
}

export const TabStrip = styled.div.attrs(addE2EAttrs)<E2E & Pick<IProps, 'alignment' | 'boxShadow' | 'noWrap'>>`
	${font(FontType.Comfortaa)}
	background: ${bgMaterial};
	text-align: ${(props) => props.alignment ?? 'center'};
	box-shadow: ${(props) => props.boxShadow ?? boxShadowMedium};
	border-radius: ${(p) => p.theme.spacing(1)}px;
	z-index: 60;
	position: relative;
	margin: 0 auto;
	max-width: 1024px;
	padding: ${(p) => p.theme.spacing(1)}px ${(p) => p.theme.spacing(2)}px 0 ${(p) => p.theme.spacing(2)}px;

	${(p) => (p.noWrap ? p.theme.breakpoints.up('xs') : p.theme.breakpoints.down('md'))} {
		white-space: nowrap;
		overflow-x: auto;

		scrollbar-width: none;
		-ms-overflow-style: none;
		&::-webkit-scrollbar {
			display: none;
		}

		${Tab} {
			margin-bottom: 2px;
		}
	}

	${Tab} {
		padding: ${(p) => p.theme.spacing(2)}px;
	}
`

export const TabContent = styled.div``

const Tabs = <TabKey extends string = string>(props: IProps<TabKey>) => {
	const {
		className,
		variant,
		onChange,
		active,
		disableRouting,
		boxShadow,
		alignment,
		children: _children,
		e2eForTabStrips,
		noWrap,
	} = props
	const children = forceArray(_children).filter(Boolean)

	return (
		<div className={className}>
			<TabStrip alignment={alignment} boxShadow={boxShadow} e2e={e2eForTabStrips} noWrap={noWrap}>
				{children.map((c, i) => {
					const DefaultTabComponentType = disableRouting ? LinkLike : Link
					const Component =
						// @ts-expect-error todo if you see this please remove this comment and fix the type error
						c.props.component !== Link ? c.props.component ?? DefaultTabComponentType : DefaultTabComponentType
					// @ts-expect-error todo if you see this please remove this comment and fix the type error
					const key = c.props.tabKey
					const isActive = active === key

					const onClick = (e: React.MouseEvent<any>) => {
						if (e.target instanceof HTMLElement) {
							e.target.scrollIntoView()
						}

						// @ts-expect-error todo if you see this please remove this comment and fix the type error
						if (c.props.onTabClick) {
							// @ts-expect-error todo if you see this please remove this comment and fix the type error
							c.props.onTabClick(e)
						} else {
							if (!isActive && onChange) {
								onChange(key)
							}
						}
					}

					const extras = {
						// @ts-expect-error todo if you see this please remove this comment and fix the type error
						to: Component === Link ? c.props.linkTo ?? key : undefined,
					}

					const tab = (
						<Tab
							key={i}
							as={Component}
							// @ts-expect-error todo if you see this please remove this comment and fix the type error
							className={c.props.className}
							onClick={onClick}
							variant={variant}
							active={isActive}
							// @ts-expect-error todo if you see this please remove this comment and fix the type error
							{...(exclude(c.props, ['children']) as any)}
							{...(disableRouting ? {} : extras)}
						>
							{/*@ts-expect-error todo if you see this please remove this comment and fix the type error*/}
							{c.props.label}
						</Tab>
					)

					// @ts-expect-error todo if you see this please remove this comment and fix the type error
					if (c.props.title) {
						return (
							// @ts-expect-error todo if you see this please remove this comment and fix the type error
							<Tooltip key={i} title={c.props.title}>
								{tab}
							</Tooltip>
						)
					}

					return tab
				})}
			</TabStrip>

			{/*@ts-expect-error todo if you see this please remove this comment and fix the type error*/}
			<TabContent>{children.find((c) => c.props.tabKey === active)?.props.children}</TabContent>
		</div>
	)
}

export default Tabs
