import { CreditCardResponse, Identifiable, WithPaymentMethodId } from 'src/travelsuit'
import { VirtualPaymentAutomation } from 'src/types/virtualPaymentAutomation'

import { getCreditCardNumbersWithName } from '../credit-card-utils'

enum PaymentMethodType {
	CreditCard = 'creditCard',
	VPA = 'vpa',
}

export type SyntheticPaymentMethodId = `${number}_${PaymentMethodType}`

function createSyntheticIdForType(id: number, paymentMethodType: PaymentMethodType): SyntheticPaymentMethodId {
	return `${id}_${paymentMethodType}`
}

export function parseSyntheticIdToApi(syntheticId: SyntheticPaymentMethodId): WithPaymentMethodId {
	const [idToParse, paymentMethodType] = syntheticId.split('_')
	const id = Number(idToParse)

	if (paymentMethodType === PaymentMethodType.CreditCard) {
		return {
			credit_card_id: id,
		}
	}

	if (paymentMethodType === PaymentMethodType.VPA) {
		return {
			virtual_payment_automation_id: id,
		}
	}

	throw new Error('Unknown payment method type')
}

export function parseSyntheticIdFromApi(withPaymentMethodId: WithPaymentMethodId) {
	if ('credit_card_id' in withPaymentMethodId) {
		return createSyntheticIdForType(withPaymentMethodId.credit_card_id, PaymentMethodType.CreditCard)
	}

	if ('virtual_payment_automation_id' in withPaymentMethodId) {
		return createSyntheticIdForType(withPaymentMethodId.virtual_payment_automation_id, PaymentMethodType.VPA)
	}

	throw new Error('No payment method id')
}

export abstract class PaymentMethod<T extends Identifiable = Identifiable> {
	abstract getName(): string

	private tooltips: string | string[] = []

	getData() {
		return this.data
	}

	constructor(
		private data: T,
		private paymentMethodType: PaymentMethodType,
	) {}

	getId(): SyntheticPaymentMethodId {
		return createSyntheticIdForType(this.getData().id, this.paymentMethodType)
	}

	getIsDisabled() {
		return !!this.getTooltips().length
	}

	getTooltips() {
		return this.tooltips
	}

	setValidationTooltips(tooltips: string | string[]) {
		this.tooltips = tooltips
	}
}

export class CreditCardPaymentMethod extends PaymentMethod<CreditCardResponse> {
	constructor(data: CreditCardResponse) {
		super(data, PaymentMethodType.CreditCard)
	}

	getName(): string {
		const data = this.getData()
		return getCreditCardNumbersWithName({ cardName: data.card_name, cardNumbers: data.card_number })
	}
}

export class VirtualPaymentAutomationPaymentMethod extends PaymentMethod<VirtualPaymentAutomation> {
	constructor(data: VirtualPaymentAutomation) {
		super(data, PaymentMethodType.VPA)
	}

	getName(): string {
		const data = this.getData()
		return `${data.card_pool_name} ${data.client_id}`
	}
}
