import classNames from 'classnames'
import React from 'react'
import ReactTooltip, { GetContentFunc, Place, TooltipProps } from 'react-tooltip'
import { useUID } from 'react-uid'

import { MobileClickArea } from 'src/atoms/MobileClickArea'
import { Portal } from 'src/common/overlays/Portal'
import { capitalize } from 'src/lib/utils'

import css from './Tooltip.module.css'

export type TooltipPlace = Place

export type IProps<C extends React.ComponentType<any> | keyof JSX.IntrinsicElements = 'span'> = Pick<
	TooltipProps,
	'effect' | 'place' | 'offset' | 'type'
> & {
	children?: React.ReactNode
	title?: string
	tipClassName?: string
	render?: GetContentFunc
	component?: C
	disable?: boolean
	multiline?: boolean
	autoWidth?: boolean
}

export default function Tooltip<C extends React.ComponentType<any> | keyof JSX.IntrinsicElements = 'span'>(
	props: IProps<C> & Omit<React.ComponentPropsWithoutRef<'span'>, keyof IProps<C>>,
) {
	const {
		className,
		tipClassName,
		children,
		title,
		component: Component = 'span',
		render,
		place,
		effect,
		disable,
		multiline,
		offset,
		type = 'dark',
		autoWidth,
		...others
	} = props
	const componentCls = classNames(css.Tooltip, className)
	// @ts-expect-error todo if you see this please remove this comment and fix the type error
	const tipCls = classNames(css.tip, tipClassName, !autoWidth && css.tipFixedWidth, css['tip' + capitalize(type)])
	const id = 'tt-' + useUID()

	const content = render?.(title ?? '') ?? title
	if (!content) {
		return (
			<Component className={componentCls} {...others}>
				{children}
			</Component>
		)
	}

	return (
		<React.Fragment>
			<Component className={componentCls} data-for={id} data-tip {...others}>
				<MobileClickArea />
				{children}
			</Component>
			<Portal>
				<ReactTooltip
					id={id}
					className={tipCls}
					type={type}
					disable={disable}
					getContent={() => content}
					offset={offset}
					place={place}
					effect={effect}
					multiline={multiline}
					overridePosition={({ left, top }, _currentEvent, _currentTarget, node) => {
						// ReactTooltip v4 positions itself incorrectly on the narrow screens
						// even if the tooltip width is less that the view port.
						if (node) {
							const d = document.documentElement
							left = Math.max(0, Math.min(d.clientWidth - node.clientWidth, left))
							top = Math.max(0, Math.min(d.clientHeight - node.clientHeight, top))
						}
						return { top, left }
					}}
				/>
			</Portal>
		</React.Fragment>
	)
}

Tooltip.hide = ReactTooltip.hide
