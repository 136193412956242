import React, { lazy, Suspense } from 'react'

export function createLazyComponentWithSuspense<T>(
	factory: () => Promise<{ default: React.ComponentType<T> }>,
	fallback: () => React.ReactElement | null = () => null,
) {
	const LazyComponent = lazy(factory)
	return function ComponentWithSuspense(props: T) {
		return (
			<Suspense fallback={fallback()}>
				{/*@ts-expect-error todo if you see this please remove this comment and fix the type error*/}
				<LazyComponent {...props} />
			</Suspense>
		)
	}
}
