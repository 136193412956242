import { sortBy } from 'lodash'
import moment from 'moment'

import { getPriceLabel } from 'src/lib/getPriceLabel'
import { PackageUsageData } from 'src/travelsuit'

import { Translations } from './UsageDetails.translations'

function isDefinedValue<T>(value: T | undefined | null | boolean): value is T {
	return value !== null && value !== undefined && typeof value !== 'boolean'
}

export function formatDate(date: string | null): string {
	return date ? moment(date).format('MMMM D, YYYY') : ''
}

function getAmountFeatureKind(amount: number): 'remaining' | 'expired' {
	return amount > 0 ? 'remaining' : 'expired'
}

export type Feature = {
	kind: 'remaining' | 'expired' | 'extra'
	scope: 'trip' | 'support_request' | 'user'
	time?: 'one_time' | 'time_period' | 'unlimited'
	label: string
}

const sortPriorities = {
	kind: ['expired', 'remaining', 'extra'],
	scope: ['trip', 'support_request', 'user'],
	time: ['one_time', 'time_period', 'unlimited', undefined],
} as const

export function getSortedUsageFeatures(usage: PackageUsageData, translations: Translations): Array<Feature> {
	const usersType = usage.users_type || ''

	function formatPrice(price: number): string {
		return getPriceLabel({ currency: usage.country_currency, price, keepZeroes: true })
	}

	const features: Array<Feature | false | undefined> = [
		usage.trip_fee_unlimited && {
			kind: 'remaining',
			scope: 'trip',
			time: 'unlimited',
			label: translations.unlimitedTrips,
		},
		!usage.trip_fee_unlimited &&
			isDefinedValue(usage.free_trip_amount_one_time) && {
				kind: getAmountFeatureKind(usage.free_trip_amount_one_time),
				scope: 'trip',
				time: 'one_time',
				label: translations.oneTimeFreeTrips({ count: usage.free_trip_amount_one_time }),
			},
		!usage.trip_fee_unlimited &&
			isDefinedValue(usage.free_trip_amount_time_period) &&
			isDefinedValue(usage.free_trip_renewal_date) && {
				kind: getAmountFeatureKind(usage.free_trip_amount_time_period),
				scope: 'trip',
				time: 'time_period',
				label: translations.periodFreeTrips({
					count: usage.free_trip_amount_time_period,
					date: formatDate(usage.free_trip_renewal_date),
				}),
			},
		!usage.trip_fee_unlimited &&
			isDefinedValue(usage.amount_of_paid_trip_fees) &&
			isDefinedValue(usage.trip_fee_price) &&
			usage.amount_of_paid_trip_fees > 0 && {
				kind: 'extra',
				scope: 'trip',
				label: translations.trips({
					count: usage.amount_of_paid_trip_fees,
					price: formatPrice(usage.trip_fee_price),
				}),
			},
		usage.support_request_fee_unlimited && {
			kind: 'remaining',
			scope: 'support_request',
			time: 'unlimited',
			label: translations.unlimitedSupportRequests,
		},
		!usage.support_request_fee_unlimited &&
			isDefinedValue(usage.free_support_request_amount_one_time) && {
				kind: getAmountFeatureKind(usage.free_support_request_amount_one_time),
				scope: 'support_request',
				time: 'one_time',
				label: translations.oneTimeFreeSupportRequests({
					count: usage.free_support_request_amount_one_time,
				}),
			},
		!usage.support_request_fee_unlimited &&
			isDefinedValue(usage.free_support_request_amount_time_period) &&
			isDefinedValue(usage.free_support_request_renewal_date) && {
				kind: getAmountFeatureKind(usage.free_support_request_amount_time_period),
				scope: 'support_request',
				time: 'time_period',
				label: translations.periodFreeSupportRequests({
					count: usage.free_support_request_amount_time_period,
					date: formatDate(usage.free_support_request_renewal_date),
				}),
			},
		!usage.support_request_fee_unlimited &&
			isDefinedValue(usage.amount_of_paid_support_request_fees) &&
			isDefinedValue(usage.support_request_fee_price) &&
			usage.amount_of_paid_support_request_fees > 0 && {
				kind: 'extra',
				scope: 'support_request',
				label: translations.supportRequests({
					count: usage.amount_of_paid_support_request_fees,
					price: formatPrice(usage.support_request_fee_price),
				}),
			},
		usage.user_fee_unlimited && {
			kind: 'remaining',
			scope: 'user',
			time: 'unlimited',
			label: translations.unlimitedUsers({ usersType }),
		},
		!usage.user_fee_unlimited &&
			isDefinedValue(usage.free_user_amount_one_time) && {
				kind: getAmountFeatureKind(usage.free_user_amount_one_time),
				scope: 'user',
				time: 'one_time',
				label: translations.oneTimeFreeUsers({
					count: usage.free_user_amount_one_time,
					usersType,
				}),
			},
		!usage.user_fee_unlimited &&
			isDefinedValue(usage.free_user_amount_time_period) &&
			isDefinedValue(usage.free_user_renewal_date) && {
				kind: getAmountFeatureKind(usage.free_user_amount_time_period),
				scope: 'user',
				time: 'time_period',
				label: translations.periodFreeUsers({
					count: usage.free_user_amount_time_period,
					usersType,
					date: formatDate(usage.free_user_renewal_date),
				}),
			},
		!usage.user_fee_unlimited &&
			isDefinedValue(usage.amount_of_paid_user_fees) &&
			usage.amount_of_paid_user_fees > 0 &&
			isDefinedValue(usage.user_fee_price) && {
				kind: 'extra',
				scope: 'user',
				label: translations.users({
					count: usage.amount_of_paid_user_fees,
					usersType,
					price: formatPrice(usage.user_fee_price),
				}),
			},
		!usage.vpa_fee_unlimited &&
			isDefinedValue(usage.amount_of_paid_vpa_fees) &&
			usage.amount_of_paid_vpa_fees > 0 &&
			isDefinedValue(usage.vpa_fee_price) && {
				kind: 'extra',
				scope: 'user',
				label: translations.vpaCardIssuances({
					count: usage.amount_of_paid_vpa_fees,
					price: formatPrice(usage.vpa_fee_price),
				}),
			},
	]

	return sortBy(features.filter(isDefinedValue) as Array<Feature>, ({ kind, scope, time }) => {
		return [
			sortPriorities.kind.indexOf(kind),
			sortPriorities.scope.indexOf(scope),
			sortPriorities.time.indexOf(time),
		].join('')
	})
}
