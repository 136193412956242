/* eslint-disable no-process-env */

enum NodeEnv {
	develop = 'development',
	production = 'production',
}

export const ENV = {
	// App
	NODE_ENV: process.env.NODE_ENV!,
	IS_DEBUG: process.env.NODE_ENV === NodeEnv.develop,
	IS_PROD: process.env.NODE_ENV === NodeEnv.production,
}

export const CURRENT_VERSION_HASH = process.env.REACT_APP_CURRENT_VERSION_HASH || 'git'
