import { TFunction } from 'i18next'

import { CreditCardPaymentMethod, PaymentMethod, SyntheticPaymentMethodId } from 'src/lib/payment-methods'
import {
	CreditCardResponse,
	ItineraryServicesWithTravelers,
	UserPaymentMethods,
	WithItineraryBookingsManagedByUserBase,
	WithItineraryServicesManagedByUser,
	WithItineraryTripFeesBase,
} from 'src/travelsuit'

export interface WithValidity {
	isValid(): boolean
}

export type CvcData = Record<number, string>

export interface WithCvcData {
	getCvcData(): CvcData
}

export interface WithFormData<T> {
	getFormData(): T
}

export interface PaymentMethodCvcData {
	value: string
	validationError?: string
}

export interface CvcControl {
	getData: () => PaymentMethodCvcData | undefined
	onChange: (cvc: string) => void
}

export interface AdditionalFee {
	getServiceName(): string
	getPrice(): number
}

export interface AdditionalFeesCalculatorInterface {
	calculateAdditionalFees(paymentMethod: PaymentMethod, services: WithItineraryServicesManagedByUser): AdditionalFee[]
}

export interface WithAdditionalFees {
	additionalFees?: AdditionalFee[]
}

export type FormUserId = number | null

export const USER_ID_FOR_COMMON_PAYMENT: FormUserId = null

export type ServiceKey = keyof ServicesPaymentMethodIds

export type ConfigPaymentMethodId = SyntheticPaymentMethodId | undefined

export type ServicesPaymentMethodIds = Partial<
	WithItineraryBookingsManagedByUserBase<
		ConfigPaymentMethodId,
		ConfigPaymentMethodId,
		ConfigPaymentMethodId,
		ConfigPaymentMethodId
	> &
		WithItineraryTripFeesBase<ConfigPaymentMethodId> & { combined: ConfigPaymentMethodId }
>

export type ServiceKeyEntry = [ServiceKey, ConfigPaymentMethodId]

export type ServicesPaymentMethodDumpRecord = [FormUserId, ServiceKeyEntry[]]

export type FormStateDump = ServicesPaymentMethodDumpRecord[]

export type ServicesByUser = Map<FormUserId, ItineraryServicesWithTravelers>

export interface ServicesControl {
	onChange: (paymentMethod: PaymentMethod) => void
	getPaymentMethods: () => PaymentMethod[]
	getSelectedPaymentMethod: () => PaymentMethod | undefined
	getServices: () => WithItineraryServicesManagedByUser
	getAdditionalFees: () => AdditionalFee[] | undefined
	selectNewTemporaryCard: (creditCard: CreditCardResponse) => void
	getCvcControl: (paymentMethod: CreditCardPaymentMethod) => CvcControl | undefined
}

export type PaymentMethodsCollection = Map<SyntheticPaymentMethodId, PaymentMethod>

export interface ServicesPaymentConfig {
	selectedPaymentMethodId: ConfigPaymentMethodId
	services: ItineraryServicesWithTravelers
}

export type ChangeListener = () => void

export interface WithFormUserId {
	userId: FormUserId
}

export interface ServiceId extends WithFormUserId {
	serviceKey: ServiceKey
}

export interface CvcStorageInterface {
	setCvcData({ paymentMethod, value }: { paymentMethod: CreditCardPaymentMethod; value: string }): void
	getCvcData(paymentMethod: CreditCardPaymentMethod): PaymentMethodCvcData | undefined
}

export type PaymentMethodsByUserId = Map<FormUserId, PaymentMethodsCollection>
export type AdditionalFeesByUserId = Map<FormUserId, AdditionalFee[]>

export interface WithFormMetadata {
	getT(): TFunction
	getRawPaymentMethods: (userId: ServiceId) => PaymentMethodsCollection | undefined
	getTemporaryCreditCards: () => PaymentMethodsCollection
	addTemporaryCreditCard: (creditCard: CreditCardResponse) => PaymentMethod
	getAdditionalFees: (serviceId: ServiceId) => AdditionalFee[] | undefined
	setPaymentMethodsByUser(usersPaymentMethods: (Omit<UserPaymentMethods, 'user_id'> & { user_id: FormUserId })[]): void
	getServicesByUser(): ServicesByUser
	getAdditionalFeesByUser: () => AdditionalFeesByUserId
	getPaymentMethodsByUser: () => PaymentMethodsByUserId
}
