import { useMemo } from 'react'

import { useTranslation } from 'src/lib/i18n/i18n'

type Countable = { count: number }
type WithUsersType = { usersType: string }
type WithDate = { date: string }
type WithPrice = { price: string }

export type Translations = ReturnType<typeof useUsageDetailsTranslations>['translation']

export const useUsageDetailsTranslations = () => {
	const { t } = useTranslation()

	const translation = useMemo(
		() => ({
			pageTitle: t('usage-details.title', 'Usage Details'),
			currentUsage: t('usage-details.current-usage', 'Current Usage'),
			startDate: t('usage-details.start-date', 'Start Date'),
			renewalDate: t('usage-details.renewal-date', 'Renewal Date'),
			automaticRenewal: t('usage-details.automatic-renewal', '(automatic renewal)'),
			packageBalance: t('usage-details.package-balance', 'Package Balance - '),
			totalFee: t('usage-details.total-fee', 'Total Fee'),
			oneTimeFreeTrips({ count }: Countable) {
				return t('usage-details.one-time-fees', '{{count}} free trips remaining (one time)', { count })
			},
			periodFreeTrips({ count, date }: Countable & WithDate) {
				return t('usage-details.period-fees', '{{count}} free trips remaining until {{date}}', { count, date })
			},
			oneTimeFreeSupportRequests({ count }: Countable) {
				return t('usage-details.one-time-support-requests', '{{count}} free support requests remaining (one time)', {
					count,
				})
			},
			periodFreeSupportRequests({ count, date }: Countable & WithDate) {
				return t('usage-details.period-support-requests', '{{count}} free support requests remaining until {{date}}', {
					count,
					date,
				})
			},
			unlimitedUsers({ usersType }: WithUsersType) {
				return t('usage-details.unlimited-users', 'Unlimited {{usersType}} users', { usersType })
			},
			unlimitedTrips: t('usage-details.unlimited-trips', 'Unlimited trips'),
			unlimitedSupportRequests: t('usage-details.unlimited-support-requests', 'Unlimited support requests'),
			trips({ count, price }: Countable & WithPrice) {
				return t('usage-details.trips', '{{count}} trips x {{price}}', { count, price })
			},
			users({ count, usersType, price }: Countable & WithUsersType & WithPrice) {
				return t('usage-details.users', '{{count}} {{usersType}} users x {{price}}', { count, usersType, price })
			},
			oneTimeFreeUsers({ count, usersType }: Countable & WithUsersType) {
				return t('usage-details.one-time-users', '{{count}} free {{usersType}} users remaining (one time)', {
					count,
					usersType,
				})
			},
			periodFreeUsers({ count, usersType, date }: Countable & WithUsersType & WithDate) {
				return t('usage-details.period-users', '{{count}} free {{usersType}} users remaining until {{date}}', {
					count,
					usersType,
					date,
				})
			},
			supportRequests({ count, price }: Countable & WithPrice) {
				return t('usage-details.support-requests', '{{count}} support requests x {{price}}', { count, price })
			},
			fee: t('usage-details.fee', 'Fee'),
			taxes: t('usage-details.taxes', 'Taxes'),
			feesNotFoundTitle: t('usage-details.fees.not-found.title', 'There are no fees yet'),
			feesNotFoundSubtitle: t(
				'usage-details.fees.not-found.subtitle',
				'Please remove or change some filters to show results',
			),
			feesCount({ count }: Countable) {
				return t('usage-details.fees.count', '{{count}} Fees', { count })
			},
			vpaCardIssuances({ count, price }: Countable & WithPrice) {
				return t('usage-details.vpa-card-issuances', '{{count}} VPA card issuances x {{price}}', { count, price })
			},
		}),
		[t],
	)

	return { translation }
}
