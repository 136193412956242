import axios, { AxiosRequestConfig } from 'axios'

import { appConfig } from 'src/app-config/appConfig'
import { WithAbortRequestConfig } from 'src/lib/requests'
import { HTTPMethod } from 'src/travelsuit'

export type LoadDataArgs = { method?: HTTPMethod; resourcePath: string; baseUrl?: string } & Pick<
	AxiosRequestConfig,
	'params' | 'data' | 'paramsSerializer' | 'onUploadProgress' | 'responseType'
>

/** @deprecated Add a function to the client */
export function loadData<T>({
	getAbortRequestConfig,
	method,
	resourcePath,
	baseUrl = appConfig.API_DOMAIN,
	...rest
}: WithAbortRequestConfig & LoadDataArgs) {
	return axios.request<T>({
		baseURL: baseUrl,
		url: resourcePath,
		...getAbortRequestConfig?.(),
		method,
		...rest,
	})
}
