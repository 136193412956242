import { defaults } from 'lodash'
import styled, { createGlobalStyle } from 'styled-components'
import { OmitIndexSignature } from 'type-fest'

import { animationDefs } from './css-helpers/animated'
import { fontSecondary } from './refactor/colors'
import { font } from './refactor/fonts'

export default styled
export { css, default as styled } from 'styled-components'

export const iconColor = (color: any) => `
	color: ${color};
`

export const iconFill = (color: any) => `
	${iconColor(color)}
	& svg{ &, & path { fill: ${color}; } }
`

export * from './css-helpers/units'
export * from './css-helpers/animated'
export * from './css-helpers/positioning'
export * from './css-helpers/media-query'
export * from './css-helpers/flex'
export * from './css-helpers/animated'
export * from './css-helpers/mui-theme'
export * from './refactor/fonts'

export const GlobalStyle = createGlobalStyle`
	${animationDefs}

	#country-menu {
		& > .MuiPaper-root {
			max-height: 30vh;
		}

		& .flag {
			display: inline-block;
		}

		& .dial-code {
			${font({ size: 14 })}
			color: ${fontSecondary};
			margin-left: 10px;
		}
	}

	#fc_frame {
		right: var(--wchat-right) !important;
		z-index: 999999 !important;
		left: unset !important;
	}

	@media screen and (max-width: 600px) {
		#fc_frame {
			display: none !important;
		}

		#fc_frame.fc-open {
			display: block !important;
		}
	}

	${(p) => p.theme.breakpoints.down('md')} {
		*::-webkit-scrollbar {
			height: 4px;
			width: 4px;
			background: transparent;
			box-shadow: none;
		}

		*::-webkit-scrollbar-thumb {
			height: 4px;
			width: 4px;
			border-radius: 5px;
			background-color: #c7c7c7;
		}
	}
`

export function defaultAttrs<T extends Record<string, unknown>>(
	props: T,
	defaultValues: Partial<OmitIndexSignature<NoInfer<T>>>,
): T {
	return defaults(props, defaultValues)
}
