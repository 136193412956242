import { parsePaymentMethods } from 'src/lib/payment-methods'
import {
	ItineraryServicesWithTravelers,
	WithBookingSegmentToTravelers,
	WithPaymentMethods,
	WithTravelers,
} from 'src/travelsuit'

import { PaymentMethodsCollection, ServicesByUser, USER_ID_FOR_COMMON_PAYMENT } from './types'

export function fillMissingServices(services: Partial<ItineraryServicesWithTravelers>) {
	return {
		flights_bookings: [],
		hotels_bookings: [],
		cars_bookings: [],
		rails_bookings: [],
		trip_fees: [],
		travelers: [],
		...services,
	}
}

export function collectPaymentMethodsById(withPaymentMethods: WithPaymentMethods): PaymentMethodsCollection {
	return parsePaymentMethods(withPaymentMethods).reduce((result, paymentMethod) => {
		result.set(paymentMethod.getId(), paymentMethod)
		return result
	}, new Map())
}

function filterBookingByUserId<T extends WithBookingSegmentToTravelers>({
	bookings,
	userId,
}: {
	bookings: T[]
	userId: number
}) {
	return bookings.filter((booking) => booking.booking_segment_to_travelers.some((bstt) => bstt.user_id === userId))
}

function splitBookingsByBookingSegmentToTraveler<T extends WithBookingSegmentToTravelers & WithTravelers>(
	bookings: T[],
) {
	return bookings.flatMap((booking) =>
		booking.booking_segment_to_travelers.flatMap((bstt) => ({
			...booking,
			booking_segment_to_travelers: [bstt],
			travelers: booking.travelers.filter(({ id }) => id === bstt.id),
		})),
	)
}

export function calculateServicesByTraveler(servicesWithTravelers: ItineraryServicesWithTravelers): ServicesByUser {
	const servicesByUser = new Map()

	const servicesSplittedByBookingSegmentToTraveler = {
		...servicesWithTravelers,
		flights_bookings: splitBookingsByBookingSegmentToTraveler(servicesWithTravelers.flights_bookings),
		hotels_bookings: splitBookingsByBookingSegmentToTraveler(servicesWithTravelers.hotels_bookings),
		cars_bookings: splitBookingsByBookingSegmentToTraveler(servicesWithTravelers.cars_bookings),
		rails_bookings: splitBookingsByBookingSegmentToTraveler(servicesWithTravelers.rails_bookings),
	}

	servicesByUser.set(USER_ID_FOR_COMMON_PAYMENT, servicesSplittedByBookingSegmentToTraveler)

	servicesSplittedByBookingSegmentToTraveler.travelers.forEach((itineraryTraveler) => {
		const userId = itineraryTraveler.user.id

		servicesByUser.set(userId, {
			...servicesSplittedByBookingSegmentToTraveler,
			travelers: [itineraryTraveler],
			flights_bookings: filterBookingByUserId({
				bookings: servicesSplittedByBookingSegmentToTraveler.flights_bookings,
				userId,
			}),
			hotels_bookings: filterBookingByUserId({
				bookings: servicesSplittedByBookingSegmentToTraveler.hotels_bookings,
				userId,
			}),
			cars_bookings: filterBookingByUserId({
				bookings: servicesSplittedByBookingSegmentToTraveler.cars_bookings,
				userId,
			}),
			rails_bookings: filterBookingByUserId({
				bookings: servicesSplittedByBookingSegmentToTraveler.rails_bookings,
				userId,
			}),
			trip_fees: servicesSplittedByBookingSegmentToTraveler.trip_fees?.filter((fee) => fee.user.id === userId),
		})
	})

	return servicesByUser
}
