import React, { memo } from 'react'

import { CreditCardBrandMap } from 'src/assets/credit-card-brands'
import SvgIcon from 'src/atoms/SvgIcon/SvgIcon'
import Tooltip from 'src/atoms/Tooltip/Tooltip'
import { forceArray } from 'src/lib/array-utils'
import { addE2EAttrs, E2E } from 'src/lib/e2e-utils'
import { insertSeparator } from 'src/lib/insertSeparator'
import QuestionCircleIcon from 'src/refactor/assets/icons/question-circle.svg'
import { disabledGray } from 'src/refactor/colors'
import styled, { textOverflow } from 'src/styles'
import { CreditCardType } from 'src/types/creditCard'

const FaqIcon = styled(SvgIcon).attrs(() => ({ src: QuestionCircleIcon }))`
	align-self: baseline;
	width: 16px;
	height: 16px;
`

const StyledTooltip = styled(Tooltip)`
	pointer-events: all;
	vertical-align: middle;
`

interface IDisabledCardFaqIconWithTooltip {
	lines: React.ReactNode[]
}

const DisabledCardFaqIconWithTooltip: React.FC<IDisabledCardFaqIconWithTooltip> = ({ lines }) => (
	<StyledTooltip onClick={(e) => e.stopPropagation()} render={() => insertSeparator(lines, <br />)}>
		<FaqIcon />
	</StyledTooltip>
)

const CardOption = styled.div.attrs(addE2EAttrs)<E2E & { disabled?: boolean }>`
	display: flex;
	overflow: hidden;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	color: ${({ disabled }) => (disabled ? disabledGray : 'inherit')};

	align-items: center;
	gap: ${(p) => p.theme.spacing(1)}px;
`

const CardText = styled.span`
	${textOverflow()}
`

const CardBrandImg = styled.img`
	width: 30px;
	height: 18px;
`

interface ICreditCardOption {
	cardBrand: CreditCardType | null
	cardName: React.ReactNode
	tooltipContent?: React.ReactNode | React.ReactNode[]
	disabled?: boolean
}

export const CreditCardOptionBase: React.FC<ICreditCardOption & E2E> = memo((props) => {
	const { cardBrand, cardName, children, tooltipContent, e2e, disabled } = props

	const tooltipLines = tooltipContent ? forceArray(tooltipContent) : undefined

	return (
		<CardOption e2e={e2e} disabled={disabled}>
			{cardBrand ? <CardBrandImg src={CreditCardBrandMap[cardBrand]} alt={cardBrand} /> : null}
			<CardText>{cardName}</CardText>
			{children}
			{!!tooltipLines?.length && <DisabledCardFaqIconWithTooltip lines={tooltipLines} />}
		</CardOption>
	)
})
