import { compareAsc, differenceInMinutes, differenceInSeconds } from 'date-fns'
import { first, isNumber, last } from 'lodash'
import moment from 'moment'

import {
	getDuration,
	HOUR_IN_MILLISECONDS,
	MINUTE_IN_MILLISECONDS,
	sortGetter,
	TimeFormatsLocaleUnsafe,
} from 'src/lib/utils'
import { getFlightProductEmissions, getFlightSliceEmissions } from 'src/organisms/FlightSearchNew/flightUtils'
import { getStoreState } from 'src/redux/stores'
import {
	Airline,
	AirportASB,
	BookingSegmentToTraveler,
	ChargeType,
	Flight,
	FlightDirection,
	FlightFilterSet,
	FlightResult,
	FlightSearchDetails,
	FlightVendor,
	Itinerary,
	ItineraryFlightBooking,
	SortingFn,
	TripDirection,
	VendorVerificationStatus,
} from 'src/travelsuit'
import {
	FlightBookingSegmentResId,
	FlightBookingSegmentResIdZ,
	FlightCarrier,
	FlightFareGroupKey,
	FlightOptionKey,
	FlightPenalties,
	FlightPenaltyFee,
	FlightUniqueKeyZ,
} from 'src/types/flights'

import { getFlightEndTime } from './getFlightEndTime'
import { getFlightStartTime } from './getFlightStartTime'
import { redirectTo3DSVerification } from './redirectTo3DSVerification'
import { getFlightsBySearchId } from './trip-utils'

export const startOfDay = (date: moment.MomentInput = new Date()) => moment(date).startOf('day').toDate()

export const endOfDay = (date: moment.MomentInput = new Date()) => moment(date).endOf('day').toDate()

export const getResultId = ({
	fare_group_key,
	flights,
}: {
	fare_group_key: FlightFareGroupKey
	flights: { flight_option_key: FlightOptionKey }[]
}): FlightBookingSegmentResId =>
	FlightBookingSegmentResIdZ.parse(
		`fare_group:${fare_group_key};flight_option_keys:${flights.map((flight) => flight.flight_option_key).join(',')}`,
	)

export function getAirportName(airport: AirportASB) {
	const airportName = airport.name || airport.location

	if (!airportName) {
		return airport.title
	}

	return `${airportName} (${airport.code})`
}

export function carrierToAirline({ code, name }: FlightCarrier): Airline {
	return {
		iata: code,
		name,
		id: undefined,
		country: '',
		alliance_id: undefined,
		is_lcc: null,
	}
}

function toToday(time: moment.MomentInput) {
	const today = new Date()
	time = moment.parseZone(time).toDate()
	return moment({
		date: today.getDate(),
		month: today.getMonth(),
		year: today.getFullYear(),
		h: time.getHours(),
		m: time.getMinutes(),
		s: time.getSeconds(),
	}).toDate()
}

export function sameDayTimeCmp(d1: moment.MomentInput, d2: moment.MomentInput): number {
	const s1 = toToday(d1)
	const s2 = toToday(d2)

	return s1.valueOf() === s2.valueOf() ? 0 : s1.valueOf() > s2.valueOf() ? 1 : -1
}

export const getAllConnections = (results: FlightResult[]) => {
	const airportIds: string[] = []
	const airports: AirportASB[] = []

	for (const result of results) {
		for (const flight of result.flights) {
			for (const seg of flight.segments.slice(1)) {
				const departureAirport = seg.departure.airport
				if (!airportIds.includes(departureAirport.code)) {
					airportIds.push(departureAirport.code)
					airports.push(departureAirport)
				}
			}
		}
	}

	return airports.sort((a, b) => getAirportName(a).localeCompare(getAirportName(b)))
}

export const getFlightTotalDuration = (flightResult: FlightResult) =>
	flightResult.flights.reduce((sum, flight) => sum + flight.duration.hours * 60 + flight.duration.minutes, 0)

export const getFlightSliceDeparture = (slice: Flight) => {
	const segment = first(slice.segments)!
	return segment.departure.datetime ?? segment.departure_time
}

export const getFlightSliceArrival = (slice: Flight) => {
	const segment = last(slice.segments)!
	return segment.arrival.datetime ?? segment.arrival_time
}

export const getFlightSliceDurationInSeconds = (slice: Flight) => {
	return differenceInSeconds(getFlightSliceArrival(slice), getFlightSliceDeparture(slice))
}

export const getFlightSliceDurationObject = (slice: Flight) => {
	const minutes = differenceInMinutes(getFlightSliceArrival(slice), getFlightSliceDeparture(slice))
	return {
		hours: Math.trunc(minutes / 60),
		minutes: minutes % 60,
	}
}

function getFlightDurationInMilliseconds(flight: Flight): number {
	return flight.duration.hours * HOUR_IN_MILLISECONDS + flight.duration.minutes * MINUTE_IN_MILLISECONDS
}

const flightSortFns: Record<FlightFilterSet['sortBy'], SortingFn<FlightResult>> = {
	earliestArrival(a, b) {
		return compareAsc(getFlightEndTime(a), getFlightEndTime(b))
	},
	earliestDeparture(a, b) {
		return compareAsc(getFlightStartTime(a), getFlightStartTime(b))
	},
	lowestCO2(a, b) {
		// We want to sort flights with missing emissions info to the end
		const emissionsA = getFlightProductEmissions(a) ?? Number.MAX_SAFE_INTEGER
		const emissionsB = getFlightProductEmissions(b) ?? Number.MAX_SAFE_INTEGER
		return emissionsA - emissionsB
	},
	price(a, b) {
		return sortGetter('total_price', a, b)
	},
	duration(a, b) {
		return getFlightTotalDuration(a) - getFlightTotalDuration(b)
	},
	recommended(a, b) {
		return sortGetter('recommendation_score', a, b)
	},
}

const flightSliceSortFns: Partial<Record<FlightFilterSet['sortBy'], SortingFn<Flight>>> = {
	earliestArrival(a, b) {
		return compareAsc(getFlightSliceArrival(a), getFlightSliceArrival(b))
	},
	earliestDeparture(a, b) {
		return compareAsc(getFlightSliceDeparture(a), getFlightSliceDeparture(b))
	},
	lowestCO2(a, b) {
		// We want to sort flights with missing emissions info to the end
		const emissionsA = getFlightSliceEmissions(a) ?? Number.MAX_SAFE_INTEGER
		const emissionsB = getFlightSliceEmissions(b) ?? Number.MAX_SAFE_INTEGER
		return emissionsA - emissionsB
	},
	duration(a, b) {
		return getFlightSliceDurationInSeconds(a) - getFlightSliceDurationInSeconds(b)
	},
}

const flightFilterFns: Record<
	keyof Omit<FlightFilterSet, 'sortBy' | 'airlinesMixed' | 'hideLcc'>,
	(flight: FlightResult, value: any, filters: FlightFilterSet) => boolean
> = {
	times(flight, dates: FlightFilterSet['times']) {
		if (!dates?.filter(Boolean).length || !flight?.flights?.[0]?.segments.length) {
			return true
		}

		return dates.every((da, i) => {
			if (!da) {
				return true
			}
			const departing = moment(
				moment
					.parseZone(flight.flights[i].segments[0].departure.datetime)
					.format('YYYY-MM-DDT' + TimeFormatsLocaleUnsafe['24HoursWithSeconds']),
			).toDate()
			const arriving = moment(
				moment
					.parseZone(flight.flights[i].segments[flight.flights[i].segments.length - 1].arrival.datetime)
					.format('YYYY-MM-DDT' + TimeFormatsLocaleUnsafe['24HoursWithSeconds']),
			).toDate()
			return (
				sameDayTimeCmp(departing, da.depart[0]) >= 0 &&
				sameDayTimeCmp(departing, da.depart[1]) <= 0 &&
				sameDayTimeCmp(arriving, da.arrive[0]) >= 0 &&
				sameDayTimeCmp(arriving, da.arrive[1]) <= 0
			)
		})
	},
	stops(flight, value: FlightFilterSet['stops']) {
		if (!flight?.flights?.[0]?.segments.length || [undefined, null].includes(value as any)) {
			return true
		}
		const stopCountsToShow = Object.keys(value).reduce(
			// @ts-expect-error todo if you see this please remove this comment and fix the type error
			(arr, c) => (value[c] || value[c] === undefined ? [...arr, Number(c)] : arr),
			[] as number[],
		)
		return flight.flights.every((f) => stopCountsToShow.includes(f.segments.length - 1) || !stopCountsToShow.length)
	},
	arrivalAirports(flight, airportSet: FlightFilterSet['arrivalAirports']) {
		if (!flight?.flights?.[0]?.segments.length || !Object.values(airportSet).some(Boolean)) {
			return true
		}
		const lastSegmentOfFirstFlight = flight.flights[0].segments[flight.flights[0].segments.length - 1]
		return !(lastSegmentOfFirstFlight.arrival_airport_id && !airportSet[lastSegmentOfFirstFlight.arrival_airport_id!])
	},
	departureAirports(flight, airportSet: FlightFilterSet['departureAirports']) {
		if (!flight?.flights?.[0]?.segments.length || !Object.values(airportSet).some(Boolean)) {
			return true
		}
		const firstSegmentOfFirstFlight = flight.flights[0].segments[0]
		return !(
			firstSegmentOfFirstFlight.departure_airport_id && !airportSet[firstSegmentOfFirstFlight.departure_airport_id!]
		)
	},
	cabinClass(flight, cabinClass: FlightFilterSet['cabinClass']) {
		const classesToShow = Object.keys(cabinClass).filter((c) => cabinClass[c] === true)
		if (!classesToShow.length) {
			return false
		}

		return flight.flights.every((f) => {
			return f.segments.every((s) => classesToShow.includes(s.cabin_class))
		})
	},
	providers(flight, vendors: FlightFilterSet['providers']) {
		const vendorsToShow = Object.keys(vendors)
			.filter((c) => vendors[c] === true)
			.map((c) => c.toLowerCase())
		if (!vendorsToShow.length) {
			return false
		}

		return vendorsToShow.includes(flight.provider.toLowerCase())
	},
	onlyInPolicy(flight, onlyInPolicy: boolean) {
		return !onlyInPolicy ? true : flight.in_policy!
	},
	onlyRefundable(flight, onlyRefundable: FlightFilterSet['onlyRefundable']) {
		const refundable = flightIsRefundable(flight)
		const changeable = flightIsChangeable(flight)

		if (!onlyRefundable) {
			return true
		}

		return Boolean(
			(refundable || !onlyRefundable.hideNonRefundable) &&
				(changeable || !onlyRefundable.hideNonChangeable) &&
				(refundable !== null || !onlyRefundable.hideUnknownRefundable) &&
				(changeable !== null || !onlyRefundable.hideUnknownChangeable),
		)
	},
	airlines(flight, airlineMap: FlightFilterSet['airlines'], filters) {
		const getAirline = (iata: string) => getStoreState().airlines.getWithFallback(iata, {} as any)
		const afterLccFilterResult = filters.hideLcc
			? flight.flights.every((f) => f.segments.every((s) => !getAirline(s.carrier.code).is_lcc))
			: true

		if (!Object.values(airlineMap).some((i) => [undefined, true].includes(i))) {
			return true && afterLccFilterResult
		}
		if (filters.airlinesMixed === false) {
			return flight.flights.every((f) =>
				f.segments.every(
					(s) =>
						[undefined, true].includes(airlineMap[s.carrier.code]) &&
						(filters.hideLcc ? !getAirline(s.carrier.code).is_lcc : true),
				),
			)
		}
		const afterMixedFilterResult = flight.flights.some((f) =>
			f.segments.some((s) => [undefined, true].includes(airlineMap[s.carrier.code])),
		)
		return afterMixedFilterResult && afterLccFilterResult
	},
	price(flight, price: FlightFilterSet['price']) {
		return price !== null ? flight.total_price >= price[0] && flight.total_price <= price[1] : true
	},
	duration(flight, range: FlightFilterSet['duration']) {
		if (!range.length) {
			return true
		}
		return range.every((r, i) => {
			if (!r) {
				return true
			}

			const duration = getFlightDurationInMilliseconds(flight.flights[i])

			return duration >= r[0] && duration <= r[1]
		})
	},
	flightUniqueKey(flight, flightKeys: string[]) {
		const curKeys = flight.flights.map((f) => flightUniqueKey(f)) as string[]
		return !flightKeys?.length || flightKeys.every((i) => curKeys.includes(i))
	},
	connectionAirports(flight, airports: Record<string, boolean>) {
		const connectingAirports = getAllConnections([flight])
		return connectingAirports.every((ap) => [true, undefined].includes(airports[ap.code]))
	},
	connectionTime(flight, times: number[]) {
		if (flight.flights.every((f) => f.segments.length === 1)) {
			return true
		}
		return flight.flights.every((f) => {
			return f.segments.slice(0, -1).every((s, i) => {
				const dur = getDuration(
					moment.parseZone(s.arrival.datetime).toDate(),
					moment.parseZone(f.segments[i + 1].departure.datetime).toDate(),
				).asMilliseconds()
				return dur >= times[0] && dur <= times[1]
			})
		})
	},
}

export function filterFlights(
	flights: FlightResult[] = [],
	filters: Partial<FlightFilterSet>,
	selectedId?: string | null,
	sliceIndex?: number,
) {
	if (!flights.length) {
		return []
	}

	if (!filters || Object.keys(filters).length === 1) {
		return flights.sort((a, b) => {
			return flightsSort(filters.sortBy!, a, b, selectedId, sliceIndex)
		})
	}

	return flights
		.filter((result) => {
			return Object.keys(filters).every((key) => {
				return (
					// @ts-expect-error todo if you see this please remove this comment and fix the type error
					filters[key] === undefined ||
					// @ts-expect-error todo if you see this please remove this comment and fix the type error
					flightFilterFns[key] === undefined ||
					// @ts-expect-error todo if you see this please remove this comment and fix the type error
					flightFilterFns[key](result, filters[key], filters)
				)
			})
		})
		.sort((a, b) => {
			return flightsSort(filters.sortBy!, a, b, selectedId, sliceIndex)
		})
}

function flightsSort(
	sortBy: FlightFilterSet['sortBy'],
	leftResult: FlightResult,
	rightResult: FlightResult,
	selectedId?: string | null,
	sliceIndex?: number,
) {
	const leftResultId = getResultId(leftResult)
	if (selectedId && (leftResultId === selectedId || getResultId(rightResult) === selectedId)) {
		return leftResultId === selectedId ? -1 : 1
	}

	if (isNumber(sliceIndex)) {
		const sortFn = flightSliceSortFns[sortBy]

		if (sortFn) {
			return sortFn(leftResult.flights[sliceIndex], rightResult.flights[sliceIndex])
		}
	}

	return flightSortFns[sortBy](leftResult, rightResult)
}

export function flightUniqueKey(flight: Flight) {
	return FlightUniqueKeyZ.parse(
		flight.segments
			.map((seg) =>
				[seg.carrier.code, seg.carrier.flight_number, seg.departure.datetime, seg.departure.airport.code].join(),
			)
			.join('-'),
	)
}

export function flightStopsCountTitle(stops: FlightSearchDetails['max_num_of_connections']): string {
	return stops ? `0 - ${stops}` : '0'
}

export function getNumOfStops(flights: any[]) {
	return Math.max(...flights.reduce((l: number[], f) => [...l, f.segments.length - 1], []))
}

export const flightTypeByNumOfStops = (num: number): FlightDirection => {
	if (num === 1) {
		return TripDirection.OneWay
	}
	if (num === 2) {
		return TripDirection.RoundTrip
	}
	return TripDirection.MultiDestination
}

export function flightHasBeforeFees({ penalties }: { penalties: FlightPenalties | null }) {
	return Boolean(
		[penalties?.change_itinerary_fees?.[0]?.total, penalties?.cancellation_fees?.[0]?.total].some(includesFee),
	)
}

export function flightHasAfterFees({ penalties }: { penalties: FlightPenalties | null }) {
	return Boolean(
		[penalties?.change_itinerary_fees?.[1]?.total, penalties?.cancellation_fees?.[1]?.total].some(includesFee),
	)
}

function filterFees(fees: FlightPenaltyFee[], afterBeforeName: 'PRIOR_DEP' | 'AFTER_DEP') {
	return fees.find((fee) => fee?.application === afterBeforeName)
}
export function getFlightPenalties({ penalties }: { penalties: FlightPenalties | null }) {
	return {
		beforeCancellation: filterFees(penalties?.cancellation_fees || [], 'PRIOR_DEP'),
		beforeChangeItinerary: filterFees(penalties?.change_itinerary_fees || [], 'PRIOR_DEP'),
		afterCancellation: filterFees(penalties?.cancellation_fees || [], 'AFTER_DEP'),
		afterChangeItinerary: filterFees(penalties?.change_itinerary_fees || [], 'AFTER_DEP'),
	}
}

export function areChangeItineraryFeesApplied({ penalties }: { penalties: FlightPenalties | null }) {
	return !!penalties?.change_itinerary_fee_applies
}

export function areCancelFlightFeesApplied({ penalties }: { penalties: FlightPenalties | null }) {
	return !!penalties?.cancellation_fee_applies
}

export function canFareDifferenceBeApplied({ penalties }: { penalties: FlightPenalties | null }) {
	return penalties?.ticket_replacement_fee_applies === null && penalties?.ticket_replacement_fees === null
}

export function flightIsRefundable({ penalties }: { penalties: FlightPenalties | null }) {
	return penalties?.ticket_refundable ?? null
}

export function flightHasBsttWithinVoidWindow(flight: ItineraryFlightBooking) {
	return flight.booking_segment_to_travelers?.some((segment) => segment.within_void_window) ?? false
}

export function flightIsChangeable({ penalties }: { penalties: FlightPenalties | null }) {
	return penalties?.ticket_changeable ?? null
}

export const isSabreNDCVendor = (vendor?: string) => vendor?.toUpperCase() === 'SABRE-NDC'

export function flightResidualValueIsSavable(flightBooking: Pick<ItineraryFlightBooking, 'penalties' | 'provider'>) {
	return !isSabreNDCVendor(flightBooking.provider) && !!flightIsChangeable(flightBooking)
}

export function includesFee(fee: number | null | undefined): fee is number {
	return ![null, undefined].includes(fee as null | undefined)
}

export function getFlightMinMaxDates(flight: FlightResult<any>) {
	return [getFlightStartTime(flight), getFlightEndTime(flight)]
}

export function getDatesLabel(dates: moment.MomentInput[], timeFormat: string) {
	return dates.map((d) => moment.parseZone(d).format(timeFormat)).join(' - ')
}

export function hasBaggage(flightSearchId: number, itinerary: Itinerary) {
	const flights = getFlightsBySearchId(itinerary, flightSearchId)
	if (!itinerary || !flights?.length) {
		return false
	}
	return itinerary.surcharges.some(
		// TechDebt: BCDTDRCT-8783, check if we have a bug here with QA
		(s) => s.charge_type === ChargeType.Baggage && s.booking_segment_id === flights[0].id,
	)
}

export const isFareLogixVendor = (vendor?: FlightVendor) => vendor?.toUpperCase() === 'FARELOGIX'
const isTravelfusionVendor = (vendor?: FlightVendor) => vendor?.toUpperCase() === 'TRAVELFUSION'

export const isTravelfusionBooking = (booking: ItineraryFlightBooking) => isTravelfusionVendor(booking.vendor)

export function isVendorWith3dsVerification(vendor?: FlightVendor) {
	return isTravelfusionVendor(vendor) || vendor?.toUpperCase() === 'FARELOGIX'
}

export function isUnverifiedBookingSegmentToTraveler({ vendor_reservation_status: status }: BookingSegmentToTraveler) {
	return !status || status === VendorVerificationStatus.Unverified
}

export function isSuccessfullyVerifiedBookingSegmentToTraveler({
	vendor_reservation_status: status,
}: BookingSegmentToTraveler) {
	return status === VendorVerificationStatus.Active
}

export function getItineraryFailedFlightBookings(itinerary: Itinerary) {
	return itinerary.notifications?.failed_bookings?.flights_bookings
}

export function getFlightBookingsWith3dsVerification(bookings: ItineraryFlightBooking[]) {
	return bookings.filter((flight) => isVendorWith3dsVerification(flight.vendor))
}

type WithBookingSegmentToTravelers<T> = T & {
	booking_segment_to_travelers?: BookingSegmentToTraveler[]
}

export function getFlightBookingBookingSegmentToTravelers<T>(booking: WithBookingSegmentToTravelers<T>) {
	return booking.booking_segment_to_travelers ?? []
}

export function getBookingSegmentToTravelersForFlightBookings<T>(bookings: WithBookingSegmentToTravelers<T>[]) {
	return bookings.flatMap(getFlightBookingBookingSegmentToTravelers)
}

export function getBookingSegmentToTravelersIdsForFlightBookings<T>(bookings: WithBookingSegmentToTravelers<T>[]) {
	return getBookingSegmentToTravelersForFlightBookings(bookings).map(({ id }) => id)
}

export function getBookingSegmentToTravelersWith3dVerificationFromBookings<T>(
	bookings: WithBookingSegmentToTravelers<T>[],
) {
	return getBookingSegmentToTravelersForFlightBookings(bookings).filter((value) => !!value.vendor_verification_url)
}

export function getBookingSegmentToTravelersWith3dVerification(itinerary: Itinerary): BookingSegmentToTraveler[] {
	return getBookingSegmentToTravelersWith3dVerificationFromBookings(
		getFlightBookingsWith3dsVerification(itinerary.flights_bookings),
	)
}

export function redirectToVendorVerification(bookingSegmentToTraveler: BookingSegmentToTraveler) {
	redirectTo3DSVerification(bookingSegmentToTraveler.vendor_verification_url!)
}
