import React from 'react'

import useTranslation from 'src/lib/i18n/UseTranslation'
import { Fee, TripFee } from 'src/travelsuit/fees.types'
import { fullName } from 'src/travelsuit/users.utils'

import { FeeCardWithPayment } from './FeeCardWithPayment'

interface TripFeeCardBaseProps<T extends TripFee> {
	fee: Fee<T>
	onFeesReload: () => void
	primaryInfo: string
	getFeeDetailsContent?: (defaultContent: React.ReactNode) => React.ReactNode
}

export function TripFeeCardBase<T extends TripFee>({
	fee,
	onFeesReload,
	primaryInfo,
	getFeeDetailsContent,
}: TripFeeCardBaseProps<T>) {
	const { t } = useTranslation()

	const {
		trip: { id: tripId },
		user,
	} = fee[fee.service_type]

	return (
		<FeeCardWithPayment
			fee={fee}
			secondaryChipText={t('usage-details.fees.trip-id', 'Trip ID - {{tripId}}', { tripId })}
			primaryInfo={primaryInfo}
			invoiceProfile={user.invoice_profile}
			travelerName={fullName(user)}
			paymentModalTitle={t('usage-details.pay-again-modal.trip', 'Trip Fee')}
			onFeesReload={onFeesReload}
			getFeeDetailsContent={getFeeDetailsContent}
		/>
	)
}
