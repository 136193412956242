import React from 'react'
import { useTranslation } from 'react-i18next'

import { VirtualPaymentAutomationPaymentMethod } from 'src/lib/payment-methods'
import { white, yellow } from 'src/refactor/colors'
import styled, { font } from 'src/styles'

import { CreditCardOptionBase } from '../CreditCardOption/CreditCardOptionBase'

interface VirtualPaymentAutomationOptionProps {
	paymentMethod: VirtualPaymentAutomationPaymentMethod
}

const CardPoolBadge = styled.div`
	background: ${yellow};
	color: ${white};
	border-radius: 3px;
	padding: 0 3px;
	height: 12px;
	${font({ size: 8, weight: 'bold' })}
	white-space: nowrap;
`

export function VirtualPaymentAutomationOption({ paymentMethod }: VirtualPaymentAutomationOptionProps) {
	const { t } = useTranslation()

	const data = paymentMethod.getData()

	return (
		<CreditCardOptionBase
			e2e="CardPool"
			disabled={paymentMethod.getIsDisabled()}
			cardBrand={data.card_type}
			cardName={paymentMethod.getName()}
			tooltipContent={paymentMethod.getTooltips()}
		>
			<CardPoolBadge>{t('virtual-payment-automation.card.card-pool', 'Card Pool')}</CardPoolBadge>
		</CreditCardOptionBase>
	)
}
