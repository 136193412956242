import { BoxProps as MuiBoxProps } from '@material-ui/core/Box'
import * as system from '@material-ui/system'
import styled from 'styled-components'

/**
 * The MUI Box is super slow for some reason (takes up to 5ms to render).
 * This code is almost the same, but uses `styled` from `styled-components` and avoids
 * a couple of additional MUI CSS wrappers. As a result is works at least 5x faster.
 */
export const Box = styled.div<Omit<MuiBoxProps, 'component'> & { ref?: React.Ref<HTMLDivElement> }>`
	${system.borders}
	${system.display}
	${system.flexbox}
	${system.positions}
	${system.palette}
	${system.shadows}
	${system.sizing}
	${system.spacing}
	${system.typography}
`

export type BoxProps = React.ComponentPropsWithRef<typeof Box> &
	system.BordersProps &
	system.DisplayProps &
	system.FlexboxProps &
	system.PositionsProps &
	system.PaletteProps &
	system.ShadowsProps &
	system.SizingProps &
	system.SpacingProps &
	system.TypographyProps
