import React from 'react'

import { LinkButton } from 'src/atoms/Button'
import { useShowModal } from 'src/atoms/ModalApi'
import { getActiveUserFeeDetails } from 'src/clients/gg/usageDetails'
import useTranslation from 'src/lib/i18n/UseTranslation'
import { formatDate } from 'src/pages/UsageDetails/getSortedUsageFeatures'
import CalendarIcon from 'src/refactor/assets/icons/calendar.svg'
import { ActiveUserFee, Fee } from 'src/travelsuit/fees.types'
import { WorldlineOrderStatus } from 'src/travelsuit/worldline'

import { ActiveUserFeeDetailsModal } from '../ActiveUserFeeDetailsModal'
import { FeeCardWithPayment } from './FeeCardWithPayment'
import { FeeDetail } from './FeeDetail'

interface ActiveUserFeeCardProps {
	fee: Fee<ActiveUserFee>
	onFeesReload: () => void
}

export function ActiveUserFeeCard({ fee, onFeesReload }: ActiveUserFeeCardProps) {
	const { t } = useTranslation()

	const { users_number, invoice_profile, id, created_dt, next_payment_date, remaining_price, total_price, status } =
		fee[fee.service_type]

	const showModal = useShowModal()

	return (
		<FeeCardWithPayment
			fee={{ ...fee, active_user: { ...fee.active_user, created_dt: '', next_payment_date: '' } }}
			getFeeDetailsContent={(content) => (
				<>
					<FeeDetail
						tooltip={t('usage-details.start-date-renewal-date', 'Start Date - Renewal Date')}
						iconSrc={CalendarIcon}
						e2e="CreationDate"
						content={[created_dt, next_payment_date].map(formatDate).join(' - ')}
					/>
					{content}
				</>
			)}
			primaryInfo={t('number-of-users', '{{count}} users', { count: users_number })}
			invoiceProfile={invoice_profile}
			paymentModalTitle={t('usage-details.pay-again-modal.active-user', 'Active User Fee')}
			onFeesReload={onFeesReload}
			displayInvoiceProfileNameForSubscription
			statusActions={
				<LinkButton
					onClick={async () => {
						const feeDetails = await getActiveUserFeeDetails({ feeId: id })
						showModal(({ close }) => {
							return (
								<ActiveUserFeeDetailsModal
									onClose={close}
									activeUserFeeDetails={feeDetails}
									startDate={new Date(created_dt)}
									endDate={new Date(next_payment_date)}
								/>
							)
						})
					}}
				>
					{t('usage-details.view-details', 'View Details')}
				</LinkButton>
			}
			additionalPriceDetails={
				status === WorldlineOrderStatus.PartialRefund
					? [
							{ label: t('usage-details.fees.total-refunded', 'Refund'), amount: remaining_price - total_price },
							{
								label: t('usage-details.fees.total-after-refund', 'Total after refund'),
								amount: remaining_price,
							},
						]
					: undefined
			}
		/>
	)
}
