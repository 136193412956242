import { TFunction } from 'src/lib/i18n/i18n.helper'
import { darkTurquoise, linkBlue, purple, successGreen, yellow } from 'src/refactor/colors'
import { ServiceFeeType } from 'src/travelsuit/fees.types'

export const FeeChipColorMap = {
	[ServiceFeeType.Trip]: successGreen,
	[ServiceFeeType.SupportRequest]: linkBlue,
	[ServiceFeeType.AnnualPayment]: yellow,
	[ServiceFeeType.MonthlyPayment]: yellow,
	[ServiceFeeType.ActiveUser]: purple,
	[ServiceFeeType.ProfiledUser]: purple,
	[ServiceFeeType.VirtualPaymentAutomation]: darkTurquoise,
}

export const getFeeLabel = (t: TFunction) => ({
	[ServiceFeeType.Trip]: t('usage-details.fees.trip', 'Trip'),
	[ServiceFeeType.SupportRequest]: t('usage-details.fees.support-request', 'Support Request'),
	[ServiceFeeType.AnnualPayment]: t('usage-details.fees.annual-payment', 'Annual Payment'),
	[ServiceFeeType.MonthlyPayment]: t('usage-details.fees.monthly-payment', 'Monthly Payment'),
	[ServiceFeeType.ActiveUser]: t('usage-details.fees.active-user', 'Active User'),
	[ServiceFeeType.ProfiledUser]: t('usage-details.fees.profiled-user', 'Profiled User'),
	[ServiceFeeType.VirtualPaymentAutomation]: t('usage-details.fees.virtual-payment-automation', 'VPA'),
})
