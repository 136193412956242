import { TFunction } from 'i18next'

import { replaceBillingRelatedText } from './replaceBillingRelatedText'

export function getTripFeeServiceText(t: TFunction) {
	return replaceBillingRelatedText(
		t('trip-payment-method.payment-table.trip-fee', 'Trip fee'),
		t('trip-fulfilment-fee', 'Trip fulfilment fee'),
	)
}
