import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useShowModal } from 'src/atoms/ModalApi'
import { parsePaymentMethodFromContainer, PaymentMethod } from 'src/lib/payment-methods'
import { useFetchData } from 'src/lib/react-hooks/useFetchData'
import { InvoiceProfile, WithPaymentMethods } from 'src/travelsuit'
import { Fee, FeesData } from 'src/travelsuit/fees.types'

import { parseFeesPaymentOptions } from '../FeesCardOption'
import BaseFeeCard from './BaseFeeCard'
import { FeeStatusBadge } from './FeeStatusBadge'
import PaymentError from './PaymentError'
import PaymentModal from './PaymentModal'
import usePayAgainFee from './usePayAgainFee'

interface FeeCardWithPaymentProps<T extends FeesData> {
	fee: Fee<T>
	onFeesReload: () => void
	invoiceProfile?: InvoiceProfile | null
	travelerName?: string
	primaryInfo: string
	secondaryChipText?: string
	paymentModalTitle: string
	canChangeDefaultCard?: boolean
	displayInvoiceProfileNameForSubscription?: boolean
	getFeeDetailsContent?: (defaultContent: React.ReactNode) => React.ReactNode
	statusActions?: React.ReactNode
	additionalPriceDetails?: { label: string; amount: number }[]
}

export function FeeCardWithPayment<T extends FeesData>({
	fee,
	onFeesReload,
	invoiceProfile,
	primaryInfo,
	travelerName,
	secondaryChipText,
	paymentModalTitle,
	canChangeDefaultCard,
	displayInvoiceProfileNameForSubscription,
	getFeeDetailsContent,
	statusActions,
	additionalPriceDetails,
}: FeeCardWithPaymentProps<T>) {
	const { t } = useTranslation()
	const [isPayAgainModalOpen, setPayAgainModalOpen] = useState(false)
	const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([])

	const show = useShowModal()

	const { handlePaymentRequest, isRequestInProgress } = usePayAgainFee(onFeesReload)

	const { fetchData } = useFetchData<WithPaymentMethods>()

	const feeServiceDetails = fee[fee.service_type]
	const { id: feeId, status } = feeServiceDetails

	const openPayAgainModal = async () => {
		const { data } = await fetchData({ resourcePath: `fee/${feeId}/payment_methods` })
		setPaymentMethods(parseFeesPaymentOptions(data, t))
		setPayAgainModalOpen(true)
	}

	const closePayAgainModal = () => setPayAgainModalOpen(false)

	const onConfirm = async (paymentMethod: PaymentMethod, isCardDefault?: boolean) => {
		const shouldHandleDefaultCardChange = canChangeDefaultCard && isCardDefault

		try {
			await handlePaymentRequest(feeId, paymentMethod)
		} catch (error) {
			if (shouldHandleDefaultCardChange) {
				onFeesReload?.()
			}
			show(({ close }) => <PaymentError onClose={close} error={error} />)
		}
		closePayAgainModal()
	}

	return (
		<>
			<BaseFeeCard
				fee={fee}
				country={invoiceProfile?.country}
				secondaryChipText={secondaryChipText}
				primaryInfo={primaryInfo}
				invoiceProfileName={invoiceProfile?.display_name || ''}
				traveler={travelerName}
				status={<FeeStatusBadge status={status} />}
				onPayAgainClick={openPayAgainModal}
				displayInvoiceProfileNameForSubscription={displayInvoiceProfileNameForSubscription}
				getFeeDetailsContent={getFeeDetailsContent}
				statusActions={statusActions}
				additionalPriceDetails={additionalPriceDetails}
			/>
			{isPayAgainModalOpen && (
				<PaymentModal
					title={paymentModalTitle}
					subtitle={t('usage-details.pay-again-modal.select-card', 'Please select credit cards and try again.')}
					onClose={closePayAgainModal}
					onConfirm={onConfirm}
					isLoading={isRequestInProgress}
					defaultInvoiceProfile={invoiceProfile}
					paymentMethods={paymentMethods}
					defaultPaymentMethod={parsePaymentMethodFromContainer(feeServiceDetails)}
					canChangeDefaultCard={canChangeDefaultCard}
				/>
			)}
		</>
	)
}
