import { SizingProps } from '@material-ui/system'
import styled from 'styled-components'

import { Box, BoxProps } from 'src/atoms/System/Box'
import { defaultAttrs } from 'src/styles'

export type CenterProps = BoxProps

/** Designed like https://www.chakra-ui.com/docs/components/center */
export const Center = styled(Box).attrs<CenterProps>((p) =>
	defaultAttrs(p, {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
	}),
)``

export type AbsoluteCenterProps = CenterProps & {
	/** @default 'both' */
	axis?: 'both' | 'horizontal' | 'vertical'
}

const absoluteCenterVariants = {
	horizontal: {
		insetStart: '50%',
		transform: 'translateX(-50%)',
	},
	vertical: {
		top: '50%',
		transform: 'translateY(-50%)',
	},
	both: {
		insetStart: '50%',
		top: '50%',
		transform: 'translate(-50%, -50%)',
	},
} as const satisfies Record<Required<AbsoluteCenterProps>['axis'], CenterProps>

/** Designed like https://www.chakra-ui.com/docs/components/center */
export const AbsoluteCenter = styled(Center).attrs<AbsoluteCenterProps>((p) =>
	defaultAttrs(p, {
		position: 'absolute',
		...absoluteCenterVariants[p.axis ?? 'both'],
	}),
)``

export type SquareProps = BoxProps & { size?: SizingProps['width'] }

export const Square = styled(Box).attrs<SquareProps>((p) =>
	defaultAttrs(p, {
		alignItems: 'center',
		display: 'flex',
		flexShrink: 0,
		flexGrow: 0,
		height: p.size,
		justifyContent: 'center',
		width: p.size,
	}),
)<SquareProps>``

export type CircleProps = SquareProps

export const Circle = styled(Square).attrs<CircleProps>((p) =>
	defaultAttrs(p, { borderRadius: '9999px' }),
)<CircleProps>``
