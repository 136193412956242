import { downloadFileByLink } from 'src/lib/downloadFileByLink'
import { replaceEntityById } from 'src/lib/entity/replaceEntityById'
import { ExpenseReportsTypes, IAction } from 'src/redux/actions'
import { ExpenseReport, ExpenseReportApi, ExpenseReportApiWithExpenseNumbers } from 'src/travelsuit/expenses'

import { normalize as normalizeExpense } from './expenses-new.reducer'

export type ExpenseReportsState = Array<ExpenseReport>

function expenseReportsReducer(state: ExpenseReportsState = [], action: IAction) {
	switch (action.type) {
		case ExpenseReportsTypes.GetReports.SUCCESS:
			return action.payload.map(normalize)
		case ExpenseReportsTypes.GetReport.SUCCESS:
		case ExpenseReportsTypes.SubmitReport.SUCCESS:
		case ExpenseReportsTypes.ApproveReport.SUCCESS:
		case ExpenseReportsTypes.RejectReport.SUCCESS:
			return replaceEntityById(state, normalize(action.payload))
		case ExpenseReportsTypes.ExportReports.SUCCESS: {
			downloadFileByLink(
				window.URL.createObjectURL(action.payload),
				`expense-reports-${new Date().toISOString().slice(0, 10)}.${action.requestPayload.fileType}`,
			)

			return state
		}
		default:
			return state
	}
}

export default expenseReportsReducer

export function normalize(report: ExpenseReportApi): ExpenseReport {
	return {
		id: report.id,
		name: report.name,
		status: report.status,
		start_date: report.start_date,
		end_date: report.end_date,
		trip_name: report.trip_name,
		description: report.description,
		submitter: report.submitter as ExpenseReport['submitter'],
		approver: report.approver,
		expenses: report.expenses.map(normalizeExpense),
		reasons: report.reasons?.map((reason) => ({
			reason: reason.reason,
			rejecter: reason.rejecter,
			id: reason.id,
			created_at: new Date(reason.created_dt),
		})),
		created_at: new Date(report.created_dt),
		modified_at: report.last_modified_dt ? new Date(report.last_modified_dt) : null,
		modified_status_at: report.last_status_modified_dt ? new Date(report.last_status_modified_dt) : null,
	}
}

export function denormalize(expenseReport: ExpenseReport): ExpenseReportApiWithExpenseNumbers {
	const denormalized = ['id', 'name', 'status', 'trip_name', 'description'].reduce(
		(acc, key) =>
			// @ts-expect-error todo if you see this please remove this comment and fix the type error
			expenseReport[key]
				? {
						...acc,
						// @ts-expect-error todo if you see this please remove this comment and fix the type error
						[key]: expenseReport[key],
					}
				: acc,
		{},
	) as ExpenseReportApi

	return {
		...denormalized,
		expenses: expenseReport.expenses.map((expense) => expense.id),
		submitter_id: expenseReport.submitter.id,
		start_date: expenseReport.start_date
			? new Date(expenseReport.start_date.toString().slice(0, 28)).toISOString().slice(0, 10)
			: undefined,
		end_date: expenseReport.end_date
			? new Date(expenseReport.end_date.toString().slice(0, 28)).toISOString().slice(0, 10)
			: undefined,
		created_dt: expenseReport.created_at.toISOString(),
		last_modified_dt: expenseReport.modified_at ? expenseReport.modified_at.toISOString() : null,
	}
}
