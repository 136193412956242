import { WithItineraryServicesManagedByUser } from 'src/travelsuit'

import { filterEmptyValues } from './array-utils'

export function getNumberOfServiceTypes(services?: WithItineraryServicesManagedByUser) {
	if (!services) {
		return 0
	}

	return filterEmptyValues(
		[
			services.flights_bookings,
			services.hotels_bookings,
			services.cars_bookings,
			services.rails_bookings,
			services.trip_fees,
		].filter((bookings) => bookings?.length),
	).length
}
