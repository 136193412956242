import { extractPaymentMethod } from 'src/lib/extractPaymentMethod'
import { extractPaymentMethodIdFromBookingSegmentToTraveler } from 'src/lib/extractPaymentMethodIdFromBookingSegmentToTraveler'
import { SyntheticPaymentMethodId } from 'src/lib/payment-methods'
import {
	ItineraryServicesWithTravelers,
	WithBookingSegmentToTravelers,
	WithItineraryServicesManagedByUser,
} from 'src/travelsuit'

import { FormStateDump, ServiceKeyEntry, ServicesByUser, USER_ID_FOR_COMMON_PAYMENT } from './types'

function getPaymentMethodIdForTripFees(tripFees: WithItineraryServicesManagedByUser['trip_fees']) {
	if (!tripFees) {
		return undefined
	}

	const tripFeeSample = tripFees[0]
	const sampleMethodId = tripFeeSample ? extractPaymentMethod(tripFeeSample)?.getId() : undefined

	if (!sampleMethodId) {
		return undefined
	}

	for (const tripFee of tripFees) {
		const paymentMethodId = extractPaymentMethod(tripFee)?.getId()
		if (paymentMethodId !== sampleMethodId) {
			return undefined
		}
	}

	return sampleMethodId
}

function getPaymentMethodForBookings<T extends WithBookingSegmentToTravelers>(bookings: T[]) {
	const bsttSample = bookings[0]?.booking_segment_to_travelers[0]
	const sampleMethodId = bsttSample ? extractPaymentMethodIdFromBookingSegmentToTraveler(bsttSample) : undefined

	if (!sampleMethodId) {
		return undefined
	}

	for (const booking of bookings) {
		for (const bstt of booking.booking_segment_to_travelers) {
			const paymentMethodId = extractPaymentMethodIdFromBookingSegmentToTraveler(bstt)
			if (paymentMethodId !== sampleMethodId) {
				return undefined
			}
		}
	}

	return sampleMethodId
}

function getPaymentMethodsIdsForServicesGroup(
	servicesByUser: ItineraryServicesWithTravelers,
	key: keyof Omit<ItineraryServicesWithTravelers, 'travelers'>,
	extractPaymentMethod: (services: ItineraryServicesWithTravelers[typeof key]) => SyntheticPaymentMethodId | undefined,
) {
	const services = servicesByUser[key]

	return {
		key,
		services,
		paymentMethodId: extractPaymentMethod(services),
	}
}

function calculateConfigForServices(servicesByUser: ItineraryServicesWithTravelers): ServiceKeyEntry[] {
	const paymentMethodsInfo = [
		getPaymentMethodsIdsForServicesGroup(servicesByUser, 'flights_bookings', getPaymentMethodForBookings),
		getPaymentMethodsIdsForServicesGroup(servicesByUser, 'hotels_bookings', getPaymentMethodForBookings),
		getPaymentMethodsIdsForServicesGroup(servicesByUser, 'cars_bookings', getPaymentMethodForBookings),
		getPaymentMethodsIdsForServicesGroup(servicesByUser, 'rails_bookings', getPaymentMethodForBookings),
		getPaymentMethodsIdsForServicesGroup(servicesByUser, 'trip_fees', getPaymentMethodIdForTripFees),
	]

	const paymentMethodsInfoWithServices = paymentMethodsInfo.filter(({ services }) => services?.length)
	const hasSamePaymentMethod =
		new Set(paymentMethodsInfoWithServices.map(({ paymentMethodId }) => paymentMethodId)).size === 1

	if (hasSamePaymentMethod) {
		return [['combined', paymentMethodsInfoWithServices[0]?.paymentMethodId]]
	}

	return paymentMethodsInfo.map(({ key, paymentMethodId }) => [key, paymentMethodId] as const)
}

export function calculateFormStateDumpByServices(servicesByUser: ServicesByUser): FormStateDump {
	const servicesClone = new Map(servicesByUser)
	servicesClone.delete(USER_ID_FOR_COMMON_PAYMENT)

	const formStateDump: FormStateDump = []
	for (const [userId, services] of servicesClone.entries()) {
		formStateDump.push([userId, calculateConfigForServices(services)])
	}

	if (formStateDump.every(([, entries]) => entries.every(([, paymentMethodId]) => !paymentMethodId))) {
		return []
	}

	if (formStateDump.length === 1) {
		formStateDump[0][0] = USER_ID_FOR_COMMON_PAYMENT
	}

	return formStateDump
}
