import { CreditCardPaymentMethod, PaymentMethod } from 'src/lib/payment-methods'
import { isSomeServiceRequiresCvv } from 'src/lib/trip-utils'
import { memoized } from 'src/lib/utils'
import { CreditCardResponse } from 'src/travelsuit'

import { mapPaymentMethods } from './mapPaymentMethods'
import {
	AdditionalFee,
	ChangeListener,
	CvcStorageInterface,
	FormUserId,
	PaymentMethodsCollection,
	ServiceId,
	ServicesControl,
	ServicesPaymentConfig,
	WithFormMetadata,
	WithValidity,
} from './types'

export class PaymentMethodControl implements ServicesControl, WithValidity {
	constructor(
		private formMetadata: WithFormMetadata,
		private serviceId: ServiceId,
		private changeListener: ChangeListener,
		private config: ServicesPaymentConfig,
		private cvcStorage: CvcStorageInterface,
		private userId: FormUserId,
	) {}

	onChange(paymentMethod: PaymentMethod) {
		this.config.selectedPaymentMethodId = paymentMethod.getId()
		this.changeListener()
	}

	@memoized()
	private getCombinedPaymentMethods(temporaryCreditCards: PaymentMethodsCollection): PaymentMethodsCollection {
		const paymentMethodsData = this.formMetadata.getRawPaymentMethods(this.serviceId) ?? new Map()

		const clonedUserPaymentMethods = new Map(paymentMethodsData)
		for (const [id, temporaryPaymentMethod] of temporaryCreditCards.entries()) {
			if (!clonedUserPaymentMethods.has(id)) {
				clonedUserPaymentMethods.set(id, temporaryPaymentMethod)
			}
		}

		return clonedUserPaymentMethods
	}

	private getAllPaymentMethods() {
		return this.getCombinedPaymentMethods(this.formMetadata.getTemporaryCreditCards())
	}

	@memoized()
	private getValidatedPaymentMethods(
		combinedPaymentMethods: PaymentMethodsCollection,
		additionalFees: AdditionalFee[] | undefined,
	) {
		return mapPaymentMethods(
			[...(combinedPaymentMethods.values() ?? [])],
			{ ...this.config.services, additionalFees },
			this.formMetadata.getT(),
		)
	}

	getPaymentMethods(): PaymentMethod[] {
		return this.getValidatedPaymentMethods(
			this.getAllPaymentMethods(),
			this.formMetadata.getAdditionalFeesByUser().get(this.userId),
		)
	}

	getSelectedPaymentMethod() {
		const paymentMethodId = this.config.selectedPaymentMethodId
		if (!paymentMethodId) {
			return undefined
		}

		return this.getAllPaymentMethods().get(paymentMethodId)
	}

	getServices() {
		return this.config.services
	}

	selectNewTemporaryCard(creditCard: CreditCardResponse) {
		const parsedCard = this.formMetadata.addTemporaryCreditCard(creditCard)
		this.onChange(parsedCard)
	}

	private isCvcRequiredForPaymentMethod(
		paymentMethod: PaymentMethod | undefined,
	): paymentMethod is CreditCardPaymentMethod {
		return (
			paymentMethod instanceof CreditCardPaymentMethod && !!paymentMethod.getData().cvc_exist && this.isCvcRequired()
		)
	}

	getCvcControl() {
		const selectedPaymentMethod = this.getSelectedPaymentMethod()

		if (!this.isCvcRequiredForPaymentMethod(selectedPaymentMethod)) {
			return undefined
		}

		return {
			getData: () => this.cvcStorage.getCvcData(selectedPaymentMethod),
			onChange: (value: string) => {
				this.cvcStorage.setCvcData({ paymentMethod: selectedPaymentMethod, value })
				this.changeListener()
			},
		}
	}

	@memoized()
	private isCvcRequired() {
		return isSomeServiceRequiresCvv(this.getServices())
	}

	isValid() {
		const paymentMethod = this.getSelectedPaymentMethod()
		if (!paymentMethod) {
			return false
		}

		if (!this.isCvcRequiredForPaymentMethod(paymentMethod)) {
			return true
		}

		const cvcData = this.cvcStorage.getCvcData(paymentMethod)
		return !cvcData?.validationError && !!cvcData?.value
	}

	getAdditionalFees() {
		return this.formMetadata.getAdditionalFees(this.serviceId)
	}
}
