import React from 'react'

import { Fee, TripFee } from 'src/travelsuit/fees.types'

import { TripFeeCardBase } from './TripFeeCardBase'

interface IProps {
	fee: Fee<TripFee>
	onFeesReload: () => void
}

const TripFeeCard: React.FC<IProps> = ({ fee, onFeesReload }) => {
	const {
		trip: { name },
	} = fee[fee.service_type]

	return <TripFeeCardBase fee={fee} onFeesReload={onFeesReload} primaryInfo={name || ''} />
}

export default TripFeeCard
