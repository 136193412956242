import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import uniqWith from 'lodash/uniqWith'

import {
	Airport,
	FlightResult,
	FlightResultMinimal,
	Itinerary,
	ItineraryFlightBooking,
	ItineraryRailBooking,
	RailDestination,
	Trip,
	WithPolicy,
} from 'src/travelsuit'
import { FlightPolicyDeviation } from 'src/types/flights'

import { FareComponent } from './fare-rules'

export function calculateDefaultUserIdForItineraryDownload(trip: Trip) {
	let userId: number | 'all' = 'all'

	if (trip.travelers.length === 1) {
		userId = trip.travelers[0].id!
	}

	return userId
}

/** Returns either the city code of the airport or it IATA if the city code does not exist
 * @param airport - The airport
 */
export function getAirportIataLabel(airport: Airport): string {
	return airport.city_code || airport.iata || airport.code
}

/** Returns either the station name in english or in local language if translation is not provided. Or 'Unknown' in case of both missing
 * @param railDestination - The rail station
 */
export function getRailStationName(railDestination?: RailDestination) {
	return railDestination?.name_en || railDestination?.name_local || 'Unknown'
}

/** Returns the flight route as such: MAD-PAR or if it is a round trip MAD-PAR-MAD
 * @param result - The flight result
 */
export function getFlightRoutesLabel(result: FlightResult<FlightResultMinimal>): string {
	const dests = Array<string>()
	result.flights.forEach((flight) => {
		if (!dests.length || dests[dests.length - 1] !== getAirportIataLabel(flight.segments[0].departure_airport)) {
			dests.push(getAirportIataLabel(flight.segments[0].departure_airport))
		}

		dests.push(getAirportIataLabel(flight.segments[flight.segments.length - 1].arrival_airport))
	})
	return dests.join(' - ')
}

export function getRailRoutes(railBooking: ItineraryRailBooking) {
	const dests = Array<string>()
	railBooking.rails.forEach((rail) => {
		rail.segments.forEach((railSegment) => {
			if (!dests.length || dests[dests.length - 1] !== getRailStationName(railSegment.departure.station)) {
				dests.push(getRailStationName(railSegment.departure.station))
			}
			dests.push(getRailStationName(railSegment.arrival.station))
		})
	})

	return dests
}

/** Returns the rail route as such: MAD-PAR or if it is a round trip MAD-PAR-MAD
 * @param result - The rail result
 */
export function getRailRoutesLabel(result: ItineraryRailBooking): string {
	return getRailRoutes(result).join(' - ')
}

export function getRailRoutesInterval(result: ItineraryRailBooking): string[] {
	const times = result.rails
		.flatMap((rail) => rail.segments)
		.flatMap((segment) => [segment.departure.datetime, segment.arrival.datetime])
		.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

	return [times[0], times[times.length - 1]]
}

/** Returns the flight route with airport names as such:
 * Ben Gurion International Airport (TLV) to Charles de Gaulle International Airport (CDG)
 * @param result - The flight result
 */
export function getFlightRoutesWithAirportNames(result: ItineraryFlightBooking): { from: string; to: string } {
	const dests = Array<string>()
	result.flights.forEach((flight) => {
		if (!dests.length || dests[dests.length - 1] !== getAirportIataLabel(flight.segments[0].departure_airport)) {
			dests.push(flight.segments[0].departure_airport.name)
		}

		dests.push(flight.segments[flight.segments.length - 1].arrival_airport.name)
	})
	return {
		from: dests[0],
		to: dests[1],
	}
}

export function itineraryPolicy(itinerary?: Itinerary): WithPolicy {
	if (!itinerary) {
		return {
			in_policy: false,
			policy_deviations: [],
		}
	}

	const bookings = [
		...(itinerary.flights_bookings ?? []),
		...(itinerary.hotels_bookings ?? []),
		...(itinerary.cars_bookings ?? []),
	]

	return {
		in_policy: bookings.every((b) => b.in_policy),
		policy_deviations: bookings.reduce<FlightPolicyDeviation[]>(
			(acc, cur) => [...acc, ...(cur?.policy_deviations ?? [])],
			[],
		),
	}
}

export const isItineraryPartiallyBooked = (itinerary?: Itinerary): boolean => {
	const failedBookings = itinerary?.notifications?.failed_bookings
	return [
		failedBookings?.flights_bookings,
		failedBookings?.hotels_bookings,
		failedBookings?.cars_bookings,
		failedBookings?.rails_bookings,
	].some((bookings) => !isEmpty(bookings))
}

export const getFlightFareRules = (itineraryFlightBooking: ItineraryFlightBooking) => {
	const fareRules = itineraryFlightBooking.flights.flatMap((fl) =>
		fl.segments.map((seg) => seg.fare_rules),
	) as FareComponent[]
	return uniqWith(fareRules, (a, b) => isEqual(a.segment_ids, b.segment_ids))
}
