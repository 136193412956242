import { TFunction } from 'i18next'

import { getCarBookingTitle } from 'src/lib/getCarBookingTitle'
import { getTripFeeServiceText } from 'src/lib/getTripFeeServiceText'
import { PaymentMethod, VirtualPaymentAutomationPaymentMethod } from 'src/lib/payment-methods'
import { WithItineraryServicesManagedByUser } from 'src/travelsuit'
import { getFlightRoutesLabel } from 'src/travelsuit/itinerary'

import { AdditionalFee, AdditionalFeesCalculatorInterface } from './types'

export class VirtualPaymentAutomationCardIssueFee<T = unknown> implements AdditionalFee {
	constructor(
		private service: T,
		private getLabel: (service: T, t: TFunction) => string,
		private price: number,
		private t: TFunction,
	) {}

	getServiceName(): string {
		return this.t('virtual-payment-automation-fee-for-service', 'VPA card issuance fee for {{service}}', {
			service: this.getLabel(this.service, this.t),
		})
	}

	getPrice(): number {
		return this.price
	}
}

interface FeesPrices {
	virtualPaymentAutomation: number
}

export class AdditionalFeesCalculator implements AdditionalFeesCalculatorInterface {
	constructor(
		private t: TFunction,
		private feesPrices: FeesPrices,
	) {}

	private getVirtualPaymentAutomationCardIssueFeesData<T>({
		servicesContainer,
		extractServices,
		getServiceLabel,
	}: {
		servicesContainer: WithItineraryServicesManagedByUser
		extractServices: (withServices: WithItineraryServicesManagedByUser) => T[]
		getServiceLabel: (service: T, t: TFunction) => string
	}) {
		return extractServices(servicesContainer).map(
			(service) =>
				new VirtualPaymentAutomationCardIssueFee(
					service,
					getServiceLabel,
					this.feesPrices.virtualPaymentAutomation,
					this.t,
				),
		)
	}

	private virtualPaymentAutomationFeeRows({
		servicesContainer,
	}: {
		servicesContainer: WithItineraryServicesManagedByUser
	}): AdditionalFee[] {
		return [
			...this.getVirtualPaymentAutomationCardIssueFeesData({
				servicesContainer,
				extractServices: (container) => container.flights_bookings,
				getServiceLabel: getFlightRoutesLabel,
			}),
			...this.getVirtualPaymentAutomationCardIssueFeesData({
				servicesContainer,
				extractServices: (container) => container.hotels_bookings,
				getServiceLabel: (hotel) => hotel.hotel_name,
			}),
			...this.getVirtualPaymentAutomationCardIssueFeesData({
				servicesContainer,
				extractServices: (container) => container.cars_bookings,
				getServiceLabel: getCarBookingTitle,
			}),
			...this.getVirtualPaymentAutomationCardIssueFeesData({
				servicesContainer,
				extractServices: (container) => container.trip_fees ?? [],
				getServiceLabel: () => getTripFeeServiceText(this.t),
			}),
		]
	}

	calculateAdditionalFees(
		paymentMethod: PaymentMethod,
		servicesContainer: WithItineraryServicesManagedByUser,
	): AdditionalFee[] {
		if (paymentMethod instanceof VirtualPaymentAutomationPaymentMethod && this.feesPrices.virtualPaymentAutomation) {
			return this.virtualPaymentAutomationFeeRows({
				servicesContainer,
			})
		}

		return []
	}
}
