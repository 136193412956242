import { TFunction } from 'i18next'

import { CreditCardPaymentMethod, SyntheticPaymentMethodId } from 'src/lib/payment-methods'
import { CVV_LENGTH_BY_CARD_TYPE } from 'src/travelsuit'
import { CreditCardType } from 'src/types/creditCard'

import { CvcStorageInterface, PaymentMethodCvcData } from './types'

export class CvcStorage implements CvcStorageInterface {
	private storage: Map<SyntheticPaymentMethodId, PaymentMethodCvcData> = new Map()

	constructor(private t: TFunction) {}

	setCvcData({ paymentMethod, value }: { paymentMethod: CreditCardPaymentMethod; value: string }) {
		const cardType = paymentMethod.getData().card_type

		const requiredCvcLength = cardType && cardType !== CreditCardType.Other ? CVV_LENGTH_BY_CARD_TYPE[cardType] : 0

		const validationError =
			requiredCvcLength === value.length
				? undefined
				: this.t('form-validation.credit-card.CVV-length-wrong', `CVC / CVV code must be {{digitsLength}} digits`, {
						digitsLength: requiredCvcLength,
					})

		this.storage.set(paymentMethod.getId(), {
			value,
			validationError,
		})
	}

	getCvcData(paymentMethod: CreditCardPaymentMethod) {
		return this.storage.get(paymentMethod.getId())
	}
}
