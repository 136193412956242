import { TFunction } from 'i18next'

import { parseCreditCard } from 'src/lib/payment-methods'
import { CreditCardResponse, ItineraryServicesWithTravelers, UserPaymentMethods } from 'src/travelsuit'

import {
	AdditionalFeesByUserId,
	FormUserId,
	PaymentMethodsByUserId,
	PaymentMethodsCollection,
	ServiceId,
	ServicesByUser,
	WithFormMetadata,
} from './types'
import { calculateServicesByTraveler, collectPaymentMethodsById } from './utils'

export class FormMetadata implements WithFormMetadata {
	private paymentMethodsByUser: PaymentMethodsByUserId
	private additionalFeesByUser: AdditionalFeesByUserId
	private temporaryCreditCardsById: PaymentMethodsCollection = new Map()
	private servicesByUser: ServicesByUser

	constructor(
		private t: TFunction,
		temporaryCreditCards: CreditCardResponse[],
		servicesWithTravelers: ItineraryServicesWithTravelers,
	) {
		this.temporaryCreditCardsById = collectPaymentMethodsById({
			credit_cards: temporaryCreditCards,
			virtual_payment_automations: [],
		})
		this.paymentMethodsByUser = new Map()
		this.additionalFeesByUser = new Map()
		this.servicesByUser = calculateServicesByTraveler(servicesWithTravelers)
	}

	setPaymentMethodsByUser(
		usersPaymentMethods: (Omit<UserPaymentMethods, 'user_id'> & { user_id: FormUserId })[],
	): void {
		this.paymentMethodsByUser = usersPaymentMethods.reduce((result, { user_id, ...paymentMethods }) => {
			result.set(user_id, collectPaymentMethodsById(paymentMethods))
			return result
		}, new Map())
	}

	getPaymentMethodsByUser() {
		return this.paymentMethodsByUser
	}

	getT(): TFunction {
		return this.t
	}

	getRawPaymentMethods(serviceId: ServiceId) {
		return this.paymentMethodsByUser.get(serviceId.userId)
	}

	getTemporaryCreditCards() {
		return this.temporaryCreditCardsById
	}

	addTemporaryCreditCard(creditCard: CreditCardResponse) {
		const parsedCard = parseCreditCard(creditCard)

		this.temporaryCreditCardsById = new Map(this.temporaryCreditCardsById)
		this.temporaryCreditCardsById.set(parsedCard.getId(), parsedCard)
		return parsedCard
	}

	getAdditionalFees(serviceId: ServiceId) {
		return ['combined', 'trip_fees'].includes(serviceId.serviceKey)
			? this.additionalFeesByUser.get(serviceId.userId)
			: undefined
	}

	getAdditionalFeesByUser() {
		return this.additionalFeesByUser
	}

	getServicesByUser(): ServicesByUser {
		return this.servicesByUser
	}
}
