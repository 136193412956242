import { separator } from 'src/_vars'
import Collapse, { Expand, Title } from 'src/atoms/Collapse/Collapse'
import { Flex, Grid } from 'src/atoms/GenericComponents'
import { SvgButton } from 'src/atoms/SvgButton'
import ChevronRightThinIcon from 'src/refactor/assets/icons/chevron-right-thin.svg'
import { fontBlack, mainBlack } from 'src/refactor/colors'
import styled, { font } from 'src/styles'

export const StyledGrid = styled(Grid)`
	min-height: 60px;
	padding: ${(p) => p.theme.spacing(2)}px 0;

	gap: ${(p) => p.theme.spacing(2)}px;
	align-items: center;

	border-top: 1px solid ${separator};
	&:last-child {
		border-bottom: 1px solid ${separator};
	}
`

export const SectionCollapse = styled(Collapse)`
	${Title} {
		color: ${fontBlack};
		font-weight: 400;
		margin-bottom: 10px;
		display: flex;
		align-items: center;
	}
`

export const usernameHeadingStyles = `
		${font({ size: 14, weight: 700 })}
		color: ${mainBlack};
		margin-bottom: 15px;

		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		gap: 1ex;
		justify-content: start;
`

export const UserDetailsCollapse = styled(Collapse)`
	> ${Title} {
		${usernameHeadingStyles}
		${Expand} {
			margin-left: 0;
		}
	}
`

export const UserDetailsTitleContent = styled(Flex)`
	display: inline-flex;
	width: 100%;
	justify-content: space-between;
	gap: ${(p) => p.theme.spacing(4)}px;
`

export const PriceContainer = styled(Flex)`
	display: inline-flex;
	justify-content: flex-end;
	flex-wrap: wrap;
	row-gap: ${(p) => p.theme.spacing(1)}px;
	column-gap: ${(p) => p.theme.spacing(2)}px;
`

export const GoToDetailSourceButton = styled(SvgButton).attrs({ src: ChevronRightThinIcon, size: 24, middle: true })``

export const DateLabel = styled.span`
	white-space: nowrap;
`
