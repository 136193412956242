import React from 'react'

import { CreditCardPaymentMethod, PaymentMethod, VirtualPaymentAutomationPaymentMethod } from 'src/lib/payment-methods'

import { CreditCardOption } from '../CreditCardOption/CreditCardOption'
import { VirtualPaymentAutomationOption } from '../VirtualPaymentAutomation/VirtualPaymentAutomationOption'

interface PaymentMethodOptionProps {
	paymentMethod: PaymentMethod
	getCardNameDetails?: (creditCardPaymentMethod: CreditCardPaymentMethod) => string | undefined
}

export function PaymentMethodOption({ paymentMethod, getCardNameDetails }: PaymentMethodOptionProps) {
	if (paymentMethod instanceof VirtualPaymentAutomationPaymentMethod) {
		return <VirtualPaymentAutomationOption paymentMethod={paymentMethod} />
	}

	if (paymentMethod instanceof CreditCardPaymentMethod) {
		return <CreditCardOption paymentMethod={paymentMethod} nameDetails={getCardNameDetails?.(paymentMethod)} />
	}

	return <></>
}
