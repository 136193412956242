import { CreditCardResponse, WithCreditCards, WithVirtualPaymentAutomations } from 'src/travelsuit'
import { VirtualPaymentAutomation } from 'src/types/virtualPaymentAutomation'

import { Maybe } from '../types'
import { CreditCardPaymentMethod, PaymentMethod, VirtualPaymentAutomationPaymentMethod } from './types'

export function parseCreditCard(creditCard: CreditCardResponse) {
	return new CreditCardPaymentMethod(creditCard)
}

export function parseVirtualPaymentAutomation(vpa: VirtualPaymentAutomation) {
	return new VirtualPaymentAutomationPaymentMethod(vpa)
}

export type DataWithPaymentMethods = Maybe<
	Partial<WithCreditCards<CreditCardResponse[] | null> & WithVirtualPaymentAutomations>
>

export function parsePaymentMethods(data: DataWithPaymentMethods): PaymentMethod[] {
	if (!data) {
		return []
	}

	const enrichedCreditCards: PaymentMethod[] = (data.credit_cards ?? []).map(parseCreditCard)
	const enrichedCardPools: PaymentMethod[] = (data.virtual_payment_automations ?? []).map(parseVirtualPaymentAutomation)

	return enrichedCreditCards.concat(enrichedCardPools)
}

export function parsePaymentMethodFromContainer(
	container: Partial<
		{ credit_card: CreditCardResponse | null } | { virtual_payment_automation: VirtualPaymentAutomation | null }
	>,
) {
	if ('credit_card' in container && container.credit_card) {
		return parseCreditCard(container.credit_card)
	}

	if ('virtual_payment_automation' in container && container.virtual_payment_automation) {
		return parseVirtualPaymentAutomation(container.virtual_payment_automation)
	}

	return undefined
}
