import { TFunction } from 'i18next'
import moment from 'moment'

import { CreditCardInfo, WithCreditCardPermissions } from 'src/travelsuit'
import { CreditCardStatus, CreditCardType } from 'src/types/creditCard'

const DIGITS_NUMBER_TO_DISPLAY = 4

export function getCreditCardLastDigits(cardNumber: string) {
	return cardNumber.slice(-DIGITS_NUMBER_TO_DISPLAY)
}

export const getHiddenCardNumber = (cardNumber: string) => `**** **** **** ${getCreditCardLastDigits(cardNumber)}`

export function getCreditCardNumbersWithName({ cardName, cardNumbers }: { cardNumbers: string; cardName: string }) {
	return `** ${getCreditCardLastDigits(cardNumbers)} ${cardName}`
}

export function getCreditCardTypeWithNumbersAndName(creditCard: CreditCardInfo) {
	return `${creditCard.card_type.toLocaleUpperCase()} ${getCreditCardNumbersWithName({
		cardNumbers: creditCard.card_number,
		cardName: creditCard.card_name,
	})}`
}

export function isCreditCardExpired({ card_expiration_date }: { card_expiration_date: string }) {
	const expDateInMomentObject = moment(card_expiration_date, 'MM/YY')
	expDateInMomentObject.add(1, 'M')

	return new Date() >= expDateInMomentObject.toDate()
}

export function isCreditCardInvalid(card: CreditCardInfo) {
	return card.status !== CreditCardStatus.Active
}

export function isRestrictedForFees(card: Pick<WithCreditCardPermissions, 'permitted_for_fees'>) {
	return !card.permitted_for_fees
}

export function getCreditCardTypeNames(t: TFunction): { [keys in CreditCardType]: string } {
	return {
		'air-plus': t('add-credit-card.card-type-items.air-plus', 'AirPlus Company Account (UATP)'),
		'amex-bta': t('add-credit-card.card-type-items.amex-bta', 'Amex Business Travel Account (BTA)'),
		'amex-bta-germany': t(
			'add-credit-card.card-type-items.amex-bta-germany',
			'Amex Business Travel Account (I-BTA for Germany)',
		),
		visa: t('add-credit-card.card-type-items.visa', 'VISA'),
		'master-card': t('add-credit-card.card-type-items.master-card', 'MasterCard'),
		amex: t('add-credit-card.card-type-items.american-express', 'American Express'),
		diners: t('add-credit-card.card-type-items.diners', 'Diners club'),
		other: t('add-credit-card.card-type-items.other', 'Other'),
	}
}
