import React, { memo } from 'react'

import { CreditCardPaymentMethod } from 'src/lib/payment-methods'

import { CreditCardOptionBase } from './CreditCardOptionBase'

interface ICreditCardOption {
	paymentMethod: CreditCardPaymentMethod
	nameDetails?: string
}

export const CreditCardOption: React.FC<ICreditCardOption> = memo((props) => {
	const { paymentMethod, nameDetails } = props

	const data = paymentMethod.getData()

	return (
		<CreditCardOptionBase
			e2e="CreditCardOption"
			disabled={paymentMethod.getIsDisabled()}
			cardBrand={data.card_type}
			cardName={
				<>
					{paymentMethod.getName()} {nameDetails}
				</>
			}
			tooltipContent={paymentMethod.getTooltips()}
		/>
	)
})
