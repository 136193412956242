import { z } from 'zod'

import { CreditCardTypeZ } from './creditCard'

export enum VirtualPaymentAutomationStatus {
	PendingValidation = 'PENDING_VALIDATION',
	Active = 'ACTIVE',
	Deactivated = 'DEACTIVATED',
}

export enum VirtualPaymentAutomationUsageAllowedFor {
	Country = 'COUNTRY',
	InvoiceProfile = 'INVOICE_PROFILE',
}

export const VirtualPaymentAutomationCreationPayloadZ = z.object({
	card_type: CreditCardTypeZ.nullable(),
	card_pool_name: z.string(),
	client_id: z.string(),
	usage_allowed_for: z.nativeEnum(VirtualPaymentAutomationUsageAllowedFor),
	available_only_for_travel_arrangers: z.boolean(),

	permitted_for_flights: z.boolean(),
	permitted_for_trains: z.boolean(),
	permitted_for_hotels: z.boolean(),
	permitted_for_cars: z.boolean(),
	permitted_for_fees: z.boolean(),
})

export type VirtualPaymentAutomationCreationPayload = z.infer<typeof VirtualPaymentAutomationCreationPayloadZ>

export const VirtualPaymentAutomationIdZ = z.number()

export type VirtualPaymentAutomationId = z.infer<typeof VirtualPaymentAutomationIdZ>

export const VirtualPaymentAutomationZ = VirtualPaymentAutomationCreationPayloadZ.extend({
	id: VirtualPaymentAutomationIdZ,
	status: z.nativeEnum(VirtualPaymentAutomationStatus),
	created_dt: z.string(),
	company_id: z.number(),
	invoice_profile: z.object({ id: z.number(), display_name: z.string() }),
})

export type VirtualPaymentAutomation = z.infer<typeof VirtualPaymentAutomationZ>
