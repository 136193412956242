import React from 'react'

import Autocomplete from 'src/atoms/Autocomplete/Autocomplete'
import { PaymentMethodOption } from 'src/atoms/PaymentMethodOption/PaymentMethodOption'
import { SvgIcon } from 'src/atoms/SvgIcon/SvgIcon'
import useTranslation from 'src/lib/i18n/UseTranslation'
import { CreditCardPaymentMethod, PaymentMethod, VirtualPaymentAutomationPaymentMethod } from 'src/lib/payment-methods'
import Card from 'src/refactor/assets/icons/card.svg'
import Reports from 'src/refactor/assets/icons/reports.svg'
import Man from 'src/refactor/assets/people/man.svg'
import { ResultSegment } from 'src/travelsuit'
import { fullName } from 'src/travelsuit/users.utils'

function compareCreditCards(a: PaymentMethod, b: PaymentMethod) {
	if (a.getIsDisabled() && !b.getIsDisabled()) {
		return 1
	}
	if (!a.getIsDisabled() && b.getIsDisabled()) {
		return -1
	}
	return a.getName().localeCompare(b.getName())
}

type PaymentMethodsAutocompleteProps = {
	paymentMethods: PaymentMethod[]
	selectedMethod: PaymentMethod | undefined | null
	onChange: (value: PaymentMethod) => void
	disabled?: boolean
	className?: string
	error?: boolean
	helperText?: string
	label?: string
	hideLabelInMobile?: boolean
}

function getInvoiceProfileFromPaymentMethod(paymentMethod: PaymentMethod) {
	return paymentMethod instanceof CreditCardPaymentMethod ||
		paymentMethod instanceof VirtualPaymentAutomationPaymentMethod
		? paymentMethod.getData().invoice_profile
		: undefined
}

function getUserFromPaymentMethod(paymentMethod: PaymentMethod) {
	return paymentMethod instanceof CreditCardPaymentMethod ? paymentMethod.getData().user : undefined
}

function getSearchValuesForPaymentMethod(paymentMethod: PaymentMethod) {
	if (paymentMethod instanceof CreditCardPaymentMethod) {
		const data = paymentMethod.getData()
		return [data.card_name, data.card_number, fullName(data.user), data.card_type, data.invoice_profile?.display_name]
	}

	if (paymentMethod instanceof VirtualPaymentAutomationPaymentMethod) {
		const data = paymentMethod.getData()
		return [data.card_pool_name, data.client_id, data.card_type, data.invoice_profile?.display_name]
	}

	return []
}

export function PaymentMethodsAutocomplete({
	paymentMethods,
	selectedMethod,
	onChange,
	disabled,
	className,
	error,
	helperText,
	label,
	hideLabelInMobile,
}: PaymentMethodsAutocompleteProps) {
	const { t } = useTranslation()

	const paymentSegments: ResultSegment[] = paymentMethods.reduce(
		(acc, method, index, arr) => {
			const invoiceProfile = getInvoiceProfileFromPaymentMethod(method)
			if (invoiceProfile && !acc.invoice[invoiceProfile.id]) {
				acc.invoice[invoiceProfile.id] = {
					icon: <SvgIcon src={Reports} />,
					key: 'invoice-' + invoiceProfile.id,
					label: invoiceProfile.display_name,
					filterFn: (paymentMethodToFilter: PaymentMethod) => {
						return getInvoiceProfileFromPaymentMethod(paymentMethodToFilter)?.id === invoiceProfile.id
					},
					alwaysExpanded: true,
				}
			}

			const user = getUserFromPaymentMethod(method)
			if (user && !acc.traveler[user.id]) {
				acc.traveler[user.id] = {
					icon: <SvgIcon src={Man} />,
					key: 'traveler-' + user.id,
					label: fullName(user),
					filterFn: (methodToFilter: PaymentMethod) => getUserFromPaymentMethod(methodToFilter)?.id === user.id,
					alwaysExpanded: true,
				}
			}

			if (index === arr.length - 1) {
				acc.segments = [
					{
						icon: <SvgIcon src={Card} />,
						key: 'temporary',
						label: t('trip-payment-method.temporary', 'Cards for this booking only (not saved)'),
						filterFn: (methodToFilter: PaymentMethod) => {
							return (
								methodToFilter instanceof CreditCardPaymentMethod &&
								!getInvoiceProfileFromPaymentMethod(methodToFilter)?.id &&
								!getUserFromPaymentMethod(methodToFilter)?.id
							)
						},
						alwaysExpanded: true,
					},
					...Object.values(acc.invoice).sort((a, b) => a.label.localeCompare(b.label)),
					...Object.values(acc.traveler).sort((a, b) => a.label.localeCompare(b.label)),
				]
			}

			return acc
		},
		{ invoice: {}, traveler: {}, segments: [] } as {
			invoice: Record<string, ResultSegment>
			traveler: Record<string, ResultSegment>
			segments: ResultSegment[]
		},
	).segments

	return (
		<Autocomplete
			key={paymentMethods.length}
			selected={selectedMethod}
			className={className}
			hideLabelInMobile={hideLabelInMobile}
			inputProps={{
				error,
				helperText,
				disabled,
				fullWidth: true,
				label: label ?? t('trip-payment-method.render-value', 'Select payment method'),
				required: true,
			}}
			segments={paymentSegments}
			alwaysShowOnOpen
			disabledCondition={(method) => method.getIsDisabled?.()}
			query={(terms) =>
				paymentMethods
					.filter((method) => {
						if (!terms) {
							return true
						}

						return [...getSearchValuesForPaymentMethod(method), method.getName()]
							.map((str) => str?.toLowerCase())
							.some((str) => str?.includes(terms.toLowerCase()))
					})
					.sort(compareCreditCards)
			}
			itemValueOf={(method: PaymentMethod) => method?.getId() ?? 0}
			labelFn={(method: PaymentMethod) => {
				return method ? method.getName() : ''
			}}
			renderOption={(method: PaymentMethod) => {
				return <PaymentMethodOption paymentMethod={method} />
			}}
			onChange={onChange}
			e2e="TripPaymentMethod"
		/>
	)
}
