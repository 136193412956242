import styled from 'styled-components'

import { accentPrimary, fgContrast, overlayGradient, spacing, white } from 'src/_vars'
import Button from 'src/atoms/Button/Button'
import { animations } from 'src/css-helpers/animated'
import { backgroundGray, brightRed, brightTurquoise, transparent, yellow } from 'src/refactor/colors'
import { pixelFontSize } from 'src/refactor/fonts'
import { flex, mediaQuery, padPx } from 'src/styles'

import Box, { BoxTitle } from '../Box/Box'
import { MobileOnly } from '../GenericComponents/GenericComponents'
import { IPropsModal } from './Modal.types'

const MAX_WIDTH = 860

interface MaxWidth {
	maxWidth?: string | number
}

interface IProps extends IPropsModal {}

export const ModalRoot = styled.div<IProps>`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: ${(props) => props.zIndex};
	${flex({ justify: 'center', align: 'center' })}
	opacity: ${(props) => (props.open ? '1' : '0')};
	${(props) => (!props.open ? `pointer-events: none;` : '')}
`

export const ModalOverlay = styled.div<{ open: boolean }>`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 5;
	background-image: ${overlayGradient};
	animation: fade 600ms ease-in-out;

	${mediaQuery.mobileOnly`
		max-height: 100vh;
		background-image: none;
		background-color: ${white};
	`}

	${(props) => (props.open ? animations.fadeIn.animate(400) : '')}
`

// @TO-DO: find a better solution for edge cases BCDTDRCT-4161
export const ModalBase = styled.div<{ open: boolean } & MaxWidth>`
	width: 100%;
	max-width: ${(props) => padPx(props.maxWidth || MAX_WIDTH)};
	position: relative;
	z-index: 10;
	background-color: white;
	border-radius: 30px;

	${(props) => (props.open ? animations.slideInTop.animate(400) : '')}

	margin: 0 auto;

	${mediaQuery.mobileOnly`
		${flex({ direction: 'column' })}

		border-radius: 0;
		padding: 0;
		position: fixed;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
	`}
`

export const ModalContent = styled.div<MaxWidth>`
	margin: 0 auto;
	width: 100%;
	max-width: ${(props) => padPx(props.maxWidth || MAX_WIDTH)};
	-webkit-overflow-scrolling: touch;
	touch-action: none;

	${mediaQuery.mobileOnly`
		max-height: 100vh !important;
		${flex({ direction: 'column' })}
		flex: 1 1;
	`}
`

const CompanyTypeModalColor = {
	success: brightTurquoise,
	alert: brightRed,
	warning: yellow,
	waiting: transparent,
}

export const ModalControlRow = styled.div<
	{ variant?: 'success' | 'warning' | 'alert' | 'waiting'; open: boolean } & MaxWidth
>`
	text-align: right;
	z-index: 300;
	pointer-events: none;
	max-width: ${(props) => padPx(props.maxWidth || MAX_WIDTH)};
	padding: 25px 30px;
	position: relative;
	background: ${(props) => (props.variant ? CompanyTypeModalColor[props.variant] : transparent)};
	border-radius: 30px 30px 0px 0px;
	color: white;
	display: flex;
	justify-content: space-between;

	${(props) =>
		props.open
			? `
		& > * {
			pointer-events: all;
		}
	`
			: ''}

	& .control-button {
		display: inline-block;
		background: #717790;
		color: ${fgContrast};
		text-align: center;
		cursor: pointer;
		transition: all 150ms ease-in-out;
		padding: 16px 25px;
		margin-left: 10px;

		&:hover {
			background: #7a809a;
		}
	}

	${mediaQuery.mobileOnly`
		border-radius: 0px;
		padding: ${spacing}px;
		margin-bottom: 0;
		height: 48px;
	`}

	${(p) => p.theme.breakpoints.down('md')} {
		background-color: ${(props) => (props.variant ? CompanyTypeModalColor[props.variant] : accentPrimary)};
	}
`

export const CloseButton = styled(Button)`
	padding: 9px !important;
	display: inline-block;
	line-height: 0.8em;
	font-size: 1.4em !important;
	text-decoration: none;
	vertical-align: middle;
	margin-left: 8px;
`

export const MobileHeaderRow = styled(MobileOnly)`
	${flex({ justify: 'space-between', align: 'center' })}
	color: ${white};
	font-size: ${pixelFontSize(20)};
	width: 100%;
`

export const BackColumn = styled.div``
export const TitleColumn = styled.div`
	font-size: ${pixelFontSize(14)};
	margin-top: 3px;
	${mediaQuery.mobileOnly`
		margin-top: 0;
	`}
`
export const DesktopTitle = styled(BoxTitle)`
	text-align: left;
	${mediaQuery.mobileOnly`display: none;`}
	font-weight: bold;
	align-self: center;
	margin-bottom: 0px;
	color: white;
`

export const BottomModalSeparator = styled.div`
	background: ${backgroundGray};
	height: 1px;
	${mediaQuery.above('mobile')`
		margin-bottom: 40px;
	`}
	${mediaQuery.mobileOnly`
		margin-bottom: 20px;
	`}
`

export const BottomModalContainer = styled.div`
	display: flex;
	${flex({ direction: 'column', justify: 'space-between' })}
	padding: 30px;
	padding-top: 0;
	background: ${white};
	border-radius: 0px 0px 30px 30px;

	${mediaQuery.mobileOnly`
		padding: 15px;
	`}
`

export const BottomModalControls = styled.div`
	display: flex;
	${flex({ direction: 'row', justify: 'space-between' })}
`

// @TO-DO: find a better solution for edge cases BCDTDRCT-4161
export const BottomModalButtonsContainer = styled.div`
	${flex({ direction: 'row', justify: 'center' })}
	margin-left: auto;
	& > *:not(:last-child) {
		margin-right: 0;
	}

	width: 100%;

	${mediaQuery.above('mobile')`
		justify-content: flex-end;
		& > *:not(:last-child) {
			margin-right: 10px;
		}
	`}
`

export const ModalBox = styled(Box).attrs(() => ({ padding: 30 }))<{
	variant: 'success' | 'warning' | 'alert' | 'waiting' | undefined
}>`
	border-radius: 0;
	max-height: calc(100vh - 400px);
	overflow-y: auto;
	min-height: 110px;
	-webkit-overflow-scrolling: touch !important;
	margin-bottom: 0;

	&:last-child {
		max-height: calc(100vh - 320px);
		border-radius: ${(props) => (props.variant === 'waiting' ? `10px` : `0px 0px 30px 30px`)};
	}

	padding-top: 40px;

	&:not(:last-child) {
		padding-bottom: 40px;
	}

	${mediaQuery.mobileOnly`
		width: 100%;
		max-width: 100vw;
		border-radius: 0;
		&:last-child {
			border-radius: 0;
		}

		max-height: 100%;

		padding: 20px 15px;
		flex: 1 1;
	`}
`

export const ButtonsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;

	width: 100%;

	${(p) => p.theme.breakpoints.up('lg')} {
		flex-direction: row;

		& > button {
			padding-right: 25px;
			padding-left: 25px;
			flex-basis: 50%;
			flex-grow: 1;
		}

		width: unset;
	}
`
