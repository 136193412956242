import { TFunction } from 'i18next'
import React from 'react'

import { PaymentMethodOption } from 'src/atoms/PaymentMethodOption/PaymentMethodOption'
import { DataWithPaymentMethods, parsePaymentMethods } from 'src/lib/payment-methods'

import { getFeesCardDisabledReasons } from '../TripPaymentMethod/form'

export function parseFeesPaymentOptions(withPaymentMethods: DataWithPaymentMethods, t: TFunction) {
	const paymentMethods = parsePaymentMethods(withPaymentMethods)
	paymentMethods.forEach((paymentMethod) => {
		const disabledReasons = getFeesCardDisabledReasons(paymentMethod, t)
		paymentMethod.setValidationTooltips(disabledReasons)
	})
	return paymentMethods
}

export function getFeesPaymentMethodOptions(withPaymentMethods: DataWithPaymentMethods, t: TFunction) {
	const paymentMethods = parseFeesPaymentOptions(withPaymentMethods, t)

	return paymentMethods.map((paymentMethod) => ({
		label: (
			<PaymentMethodOption
				paymentMethod={paymentMethod}
				getCardNameDetails={(cardPaymentMethod) => {
					return cardPaymentMethod.getData().related_to === 'company'
						? t('trip-payment-method.company-card', '(Company card)')
						: ''
				}}
			/>
		),
		value: paymentMethod.getId(),
		disabled: paymentMethod.getIsDisabled(),
		paymentMethod,
	}))
}
