import { TripPaymentMethodBase } from 'src/travelsuit'

import { parseCreditCard, parseVirtualPaymentAutomation } from './payment-methods'

export function extractPaymentMethod<T extends TripPaymentMethodBase>(container?: T | null) {
	if (container?.credit_card) {
		return parseCreditCard(container.credit_card)
	}

	if (container?.virtual_payment_automation) {
		return parseVirtualPaymentAutomation(container.virtual_payment_automation)
	}

	return undefined
}
